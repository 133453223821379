export const getAllLeads = async (payload, authToken, signal=null) => {
	try {
		
		const { isSorted, sortBy, searchText, currentFolderName} = payload
	    const url = `${process.env.REACT_APP_BASE_URL}/getAllLeads?currentFolderName=${currentFolderName}&searchText=${searchText}${sortBy? '&sortBy='+sortBy: ''}&isSorted=${isSorted}`
	
		return await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			signal
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from EXam : Get all getAllLeads', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getLeadsFoldersCount = async (authToken) => {
	try {
	    const url = `${process.env.REACT_APP_BASE_URL}/getLeadsFoldersCount`
	
		return await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from EXam : Get all getLeadsFoldersCount', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const createLead = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/createLead/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Leads : createLead()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const claimTheLeads = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/claimTheLeads/`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Leads : claimTheLeads()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
export const moveToRelease = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/moveToRelease/`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Leads : moveToRelease()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
export const transferTheLeads = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/transferTheLeads/`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Leads : transferTheLeads()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
export const moveToWaiting = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/moveToWaiting/`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Leads : moveToWaiting()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
export const leadMoveToFolder = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/leadMoveToFolder/`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Leads : leadMoveToFolder()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
export const addCallNotes = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addCallNotes/`, {
			method: 'POSt',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Leads : addCallNotes()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
export const addCommentOnLead = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addCommentOnLead/`, {
			method: 'POSt',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Leads : addCommentOnLead()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
export const getLeadActivities = async (leadId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getLeadActivities/${leadId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Query : getLeadActivities()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const autoUpdateLeads = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/autoUpdateLeads/`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Leads : autoUpdateLeads()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateLeadStatus = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateLeadStatus/`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Leads : updateLeadStatus()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};