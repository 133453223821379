
import {colors} from '@assets/colors'
import './styles.css'
const paymentOptions = [
    // { _id: "1", paymentGateway: "razorpay", text: "RazorPay" },
    { _id: "2", paymentGateway: "eazypay", text: "ICICI - LLP" },
    { _id: "3", paymentGateway: "stripe", text: "Wio - FZC" },
    { _id: "4", paymentGateway: "ccavenue", text: "HDFC" },
    { _id: "5", paymentGateway: "idfc", text: "IDFC -Pvt Ltd" },
    { _id: "6", paymentGateway: "axis", text: "Axis - Pvt Ltd" },
  ];
function DistributionBar(props){

    return <div className='w-100 d-flex distBar flex-column'>
            <div className="w-100 d-flex rounded overflow-hidden">
                {props.data?.map((obj, index)=>
                <div  style={{flex: obj?.percentage/100, height:'6px', backgroundColor: colors[index]}}></div>)}
                
            </div>
            <div className="hide flex-column gap-1 ">
                    {props.data?.map((obj, index)=>
                    <div className='d-flex flex-row justify-content-center gap-1 alig-items-center'>
                        <div className='circle' style={{backgroundColor: colors[index]}}></div>
                        <div>{paymentOptions.filter(option=>option.paymentGateway ===obj.name)[0]?.text} </div>
                        <div className="ms-auto">{Math.trunc(obj.percentage)}% </div> 
                        </div>)}
            </div>
        </div>
}

export default DistributionBar