export const defaultTabs = [
  {
    _id: "all",
    title: "All",
    isActive: true,
  },
  // {
  //   _id: "razorpay",
  //   title: "RazorPay",
  //   isActive: false,
  // },
  {
    _id: "stripe",
    title: "Wio - FZC",
    isActive: false,
  },
  {
    _id: "eazypay",
    title: "ICICI - LLP",
    isActive: false,
  },
  {
    _id: "idfc",
    title: "IDFC -Pvt Ltd",
    isActive: false,
  },
  {
    _id: "axis",
    title: "Axis - Pvt Ltd",
    isActive: false,
  },
  {
    _id: "ccavenue",
    title: "HDFC",
    isActive: false,
  },
];
