import React from 'react';
import './style.css';
import { Tabs, Tab } from 'react-bootstrap';

import Courses from '@pages/Content/Courses/Main';
import Series from '@pages/Content/Series/Main';
import Videos from '@pages/Content/Videos/Main';
import CourseCategory from '@pages/Content/CourseCategories/Main';
import Exam from '@pages/Content/Exams/Main';
import Tests from '@pages/Content/Tests/Main';
import Faq from '@pages/Content/FAQ/Main';


function ContentView(props) {
	return (
		<>
			<div className='contentPageMainContainer'>
				<h4 style={{ marginBottom: '20px', marginLeft: '20px' }}> Content </h4>

				<Tabs
					defaultActiveKey={props.selectedTab}
					id='myTabs'
					onSelect={props.handleTabSelect}>
					<Tab eventKey='videos' title='Videos'>
						<Videos isSelected = {props.selectedTab === 'videos'}/>
					</Tab>
					<Tab eventKey='courses' title='Courses'>
						<Courses isSelected={props.selectedTab === 'courses'}/>
					</Tab>
					<Tab eventKey='series' title='Series'>
						<Series isSelected={props.selectedTab === 'series'}/>
					</Tab>
					<Tab eventKey='courseCategory' title='Course Category'>
						<CourseCategory isSelected={props.selectedTab === 'courseCategory'}/>
					</Tab>
					<Tab eventKey='exams' title='Exams'>
						<Exam isSelected={props.selectedTab === 'exams'}/>
					</Tab>
					<Tab eventKey='tests' title='Tests'>
						<Tests isSelected={props.selectedTab === 'tests'}/>
					</Tab>
					<Tab eventKey='faq' title='FAQs'>
						<Faq isSelected={props.selectedTab === 'faq'}/>
					</Tab>
				</Tabs>
			</div>
		</>
	);
}

export default ContentView;
