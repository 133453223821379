import React, { useState} from 'react';

import AnalyticsView  from './view';
import SidebarNavigation from '@components/SidebarNavigation';

function Analytics(props) {
    const [activeTab, setActiveTab] = useState("1");

    return(
        <>
            <SidebarNavigation />
            <AnalyticsView
                activeTab = {activeTab}
                handleTabClick= {setActiveTab}
            />
        </>
    )
}

export default Analytics