import React, { useEffect } from 'react';

import './style.css';
import DynamicTabs from '@components/DynamicTabs';

import AnalyticsOverView from '@pages/Analytics/Overview'
import AnalyticsLeads from '@pages/Analytics/Leads'
import AnalyticsGeography from '@pages/Analytics/Geography'
import { Tabs, Tab } from 'react-bootstrap';

import { checkLoginAndRole } from "@helpers/Login/";
function AnalyticsView(props){

	useEffect(() => {
		checkLoginAndRole('analytics');
	}, []);

    return(
        <div className='BSSPageMainContainer bg-white fix' ref={props.fullscreenElementRef} style={{overflow:'hidden'}}>
            <h4 className='mb-3'>Analytics</h4>
			<Tabs
				defaultActiveKey={"1"}
				// id='myTabs'
				onSelect={props.handleTabClick}
				>
				<Tab eventKey='1' title='Overview'>
					<AnalyticsOverView isSelected = {props.activeTab === '1'}/>
				</Tab>
				<Tab eventKey='2' title='Leads'>
					<AnalyticsLeads isSelected = {props.activeTab === '2'}/>
				</Tab>
				<Tab eventKey='3' title='Geography'>
					<AnalyticsGeography isSelected = {props.activeTab === '3'}/>
				</Tab>
			</Tabs>
        </div>
    )
}

export default AnalyticsView