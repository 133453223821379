import React, { useState, useEffect, useRef } from "react";
import { defer, useLocation } from "react-router-dom";

import BSSView from "./view";
import { checkLoginAndRole } from "@helpers/Login/";
import SidebarNavigation from "@components/SidebarNavigation";
import * as BSSServices from "@services/BSS";
import * as UndergraduateDegreeServices from "@services/UndergraduateDegree";

import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";
import { getEventElementPosition, toggleFullscreen } from "@helpers/common.js";
import BSSForm from "../BSS Form";
import { jsPDF } from "jspdf";

import { renderToStaticMarkup } from "react-dom/server";
import { saveAs } from "file-saver";
import * as FolderServices from "@services/Folders";
import { handleExcelImport } from "@helpers/Users";
import { excelSheetHeaders } from "./data";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as TabsActions from "@redux/actions/Tabs";
import { useNavigate } from "react-router-dom";

import { getAllTags } from "@helpers/common";
import * as TagsActions from "@redux/actions/Tags";
import { debounce } from "../../../helpers/common";
var JSZip = require("jszip");
var listOfCheckedRows = [];
var roleCode, role;
var pageData = {};

function BSS(props) {
  const navigate = useNavigate();
  const fullscreenElementRef = useRef(null);
  const BSSFormRef = useRef(null);
  const columnsOptionPopUpRef = useRef(null);
  const excelFileInputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnsOptionPopUpRef.current &&
        !columnsOptionPopUpRef.current.contains(event.target)
      ) {
        columnsOptionPopUpRef.current.style.display = "none";
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const [deferredData, setDeferredData] = useState({});
  // const [pageData, setPageData] =useState({});
  const [searchText, setSearchText] = useState("");
  const [isSorted, setIsSorted] = useState(true);
  const [sortBy, setSortBy] = useState("createdAt");
  const [folders, setFolders] = useState([]);
  const [activeTab, setActiveTab] = useState(props.tab);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  const page = Number(queryParams.get("page"));

  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);
  const [pdfData, setPdfData] = useState(null);

  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});

  const [allCourses, setAllCourses] = useState([]);
  const [allInstitute, setAllInstitute] = useState([]);

  const [formDate, setFormDate] = useState("");
  const [isThumbnailVisible, setIsThumbnailVisible] = useState(false);
  const [undoPurchaseList, setUndoPurchaseList] = useState(false);

  const [clikedRowId, setClikedRowId] = useState(null);
  const [allUnderGraduateDegrees, setAllUnderGraduateDegrees] = useState([]);
  const controller = useRef(new AbortController());

  useEffect(() => {
    [role, roleCode] = checkLoginAndRole("users");
    getTags();
    return ()=>{
      controller.current.abort();
    }
  }, []);

  useEffect(() => {
    getAllFolders();
  }, [props.access]);

  useEffect(() => {
    getAllCourses();
    getAllInstitute();
    getAllUnderGraduateDegrees();
  }, []);

  useEffect(() => {
    if (activeTab._id === "Pending") {
      getAllPendingBssForms();
      return;
    }
    getAllBssForms();
  }, [page, limit, isSorted, sortBy, activeTab, searchText]);

  const getTags = async () => {
    try {
      const allTags = await getAllTags("manual");
      if (allTags) {
        props.updateTags(allTags);
      } else {
        console.log("Error coming while getting all Tags");
      }
    } catch (err) {
      console.log("Error coming while getting all Tags", err);
    }
  };

  const getAllBssForms = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: page,
        limit: limit ? limit : 20,
        isSorted: isSorted,
        sortBy: sortBy,
        searchText: searchText,
        folderType: activeTab.title,
        // visibility: 'all',
        pageType: "bss",
        startDate: "",
        endDate: "",
        userId: "",
      };

      if (props?.userId) {
        payload.userId = props.userId;
      }
      controller.current = new AbortController()
      let signal = controller.current.signal;
      var deferredResponse = defer({
        res: BSSServices.getAllBssForms(payload, authToken, signal),
      });
      setDeferredData(deferredResponse);
      deferredResponse.data.res
        .then((response) => {
          pageData = response;
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    } catch (err) {
      console.log("Error coming while getting all BSS Forms", err);
    }
  };
  const getAllPendingBssForms = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: page,
        limit: limit ? limit : 20,
        isSorted: isSorted,
        sortBy: sortBy,
        searchText: searchText,
        folderType: activeTab.title,
        // visibility: 'all',
        pageType: "bss",
        startDate: "",
        endDate: "",
        userId: "",
      };

      if (props?.userId) {
        payload.userId = props.userId;
      }

      var deferredResponse = defer({
        res: BSSServices.getAllPendingBssForms(payload, authToken),
      });
      setDeferredData(deferredResponse);
      deferredResponse.data.res
        .then((response) => {
          pageData = response;
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    } catch (err) {
      console.log("Error coming while getting all BSS Forms", err);
    }
  };

  const getAllFolders = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      pageNo: "0",
      limit: "10000",
      isSorted: true,
      sortBy: "title",
      searchText: "",
      visibility: "active",
    };
    const response = await FolderServices.getAllFolders(payload, authToken);
    var allFolders = response.data;
    allFolders = allFolders
      .map((folder) => (folder.pageType === "bss" ? folder : null))
      .filter(Boolean);

    if (roleCode === 2 || roleCode === "2") {
      if (props.access?.bssRights) {
        allFolders = allFolders.filter((folder) =>
          props.access.bssRights.includes(folder._id)
        );
      } else {
        allFolders = [];
      }
    }

    var defaultFolder = {
      _id: "All", // Provide a unique ID for the new folder
      title: "All",
      isActive: true,
    };
    var defaultFolder2 = {
      _id: "Pending", // Provide a unique ID for the new folder
      title: "Pending",
      isActive: false,
    };

    allFolders.unshift(defaultFolder2);
    allFolders.unshift(defaultFolder);
    setFolders(allFolders);
  };
  const getAllCourses = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = { pageNo: "0", limit: "10000", visibility: "active" };
      var response = await BSSServices.getAllBssCourses(payload, authToken);

      if (response.success) {
        setAllCourses(response.data);
      } else {
        console.log(response.error);
      }
    } catch (err) {
      console.log("Error coming while fetching all courses", err);
    }
  };
  const getAllInstitute = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: 0,
        limit: 99999,
        isSorted: false,
        sortBy: "createdAt",
        searchText: "",
        visibility: "active",
      };
      var response = await BSSServices.getAllInstitutes(payload, authToken);

      if (response.success) {
        setAllInstitute(response.data);
      } else {
        console.log(response.error);
      }
    } catch (err) {
      console.log("Error coming while fetching all courses", err);
    }
  };

  const getAllUnderGraduateDegrees = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: 0,
        limit: 99999,
        isSorted: false,
        sortBy: "createdAt",
        searchText: "",
        visibility: "active",
      };
      var response = await UndergraduateDegreeServices.getAllUGDegrees(
        payload,
        authToken
      );

      if (response.success) {
        setAllUnderGraduateDegrees(response.data);
      } else {
        console.log(response.error);
      }
    } catch (err) {
      console.log("Error coming while fetching all courses", err);
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.text);
  };

  //Fn to check and uncheck all checkboxes
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedRows = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };
  // To sort
  const handleSort = (data) => {
    const mapping = {
      "Date Created": "createdAt",
      "Date Modified": "updatedAt",
    };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    setSortBy(id);
    setIsSorted(data[0]?.desc ? data[0].desc : false);
  };
  const handleColumnBtnClick = (event) => {
    handlePopUpClose();
    columnsOptionPopUpRef.current.style.display = "block";
    var position = getEventElementPosition(event);
    columnsOptionPopUpRef.current.style.top = position.top + "px";
    columnsOptionPopUpRef.current.style.left = position.left - 160 + "px";
    event.stopPropagation();
  };
  const handlePdf = async (data) => {
    if (data === "bulk") {
      data = listOfCheckedRows;
    } else {
      data = [data];
    }

    var zip = new JSZip();

    const generatePdf = async (singleForm, i) => {
      try {
        const doc = new jsPDF("p", "pt", "a4", true);
        setPdfData(singleForm);

        var BSSFormComponent = <BSSForm data={singleForm} />;
        const htmlPDFPage = renderToStaticMarkup(BSSFormComponent);
        doc.internal.write(0, "Tw");

        // Wrap doc.html in a promise
        const pdfPromise = new Promise((resolve) => {
          doc.html(htmlPDFPage, {
            callback: function () {
              resolve(doc);
            },
          });
        });

        // Wait for the promise to resolve before continuing
        const generatedDoc = await pdfPromise;

        if (data.length > 1) {
          zip.file(
            i + 1 + singleForm.fullName + ".pdf",
            generatedDoc.output("blob")
          );
        } else {
          generatedDoc.save(singleForm.fullName + ".pdf");
        }
      } catch (err) {
        console.log("Error", err);
      }
    };

    for (let i = 0; i < data.length; i++) {
      await generatePdf(data[i], i);
    }

    if (data.length > 1) {
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "bssForms.zip");
      });
    }
  };
  // To close the popup container
  const handlePopUpOpen = (event, popUpName, itemId = "") => {
    handlePopUpClose();
    setPopUp(popUpName);

    itemId ? setClikedRowId(itemId) : setClikedRowId(null);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    columnsOptionPopUpRef.current.style.display = "none";
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async (data) => {
    if (popUp === "add/changeCourse") {
      updateBssCourseDetails(data);
    } else if (popUp === "changeExamYear") {
      updateBssExaminationYear(data);
    } else if (popUp === "courseDuration") {
      updateBssCourseDuration(data);
    } else if (popUp === "changeInstitute") {
      updateBssInstituteId(data);
    } else if (popUp === "moveTo") {
      moveToFolder(data);
    } else if (popUp === "dateOfForm") {
      data = new Date(data);
      data = data.toISOString().slice(0, 10) + "T00:00:00.000+00:00";
      updateBSSFormDate(data);
    } else if (popUp === "underGraduateDegree") {
      updateBssUnderGraduateDegree(data);
    }
    handlePopUpClose();
    // getAllBssForms();
    // setDisableBulkActionBtn(true);
  };
  const updateBssCourseDetails = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        purchaseIds: listOfCheckedRows.map((selected) => selected.purchaseId),
        bssCourseId: data._id,
      };

      var response = await BSSServices.updateBssCourseDetails(
        payload,
        authToken
      );

      if (response.success) {
        getAllBssForms();
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while changing the course", err);
    }
  };
  const updateBssExaminationYear = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        purchaseIds: listOfCheckedRows.map((selected) => selected.purchaseId),
        fromYear: data.from,
        toYear: data.to,
      };

      var response = await BSSServices.updateBssExaminationYear(
        payload,
        authToken
      );

      if (response.success) {
        getAllBssForms();
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while updating examination year.", err);
    }
  };
  const updateBssCourseDuration = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        purchaseIds: clikedRowId
          ? [clikedRowId]
          : listOfCheckedRows.map((selected) => selected.purchaseId),
        duration: data.isDurationInYear
          ? data.courseDuration
          : data.courseDuration + " months",
      };

      var response = await BSSServices.updateBssCourseDuration(
        payload,
        authToken
      );

      if (response.success) {
        getAllBssForms();
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while updating course duration.", err);
    }
  };

  const updateBssUnderGraduateDegree = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        bssDocIds: clikedRowId ? [clikedRowId] : [],
        underGraduateDegree: data.length > 0 ? data[0].title : "",
      };

      var response = await BSSServices.updateBssUnderGraduateDegree(
        payload,
        authToken
      );

      if (response.success) {
        getAllBssForms();
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while updating course duration.", err);
    }
  };

  const updateBssInstituteId = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        purchaseIds: listOfCheckedRows.map((selected) => selected.purchaseId),
        bssInstituteId: data._id,
      };

      var response = await BSSServices.updateBssInstituteId(payload, authToken);

      if (response.success) {
        getAllBssForms();
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while updating institution.", err);
    }
  };
  const moveToFolder = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      var payload = {
        foldersIdList: data,
        usersList: listOfCheckedRows.map((row) => ({
          userId: row.userId,
          purchaseId: row.purchaseId,
          type: row.purchaseId ? "purchases" : "unpurchases",
        })),
        currentFolderId: activeTab._id == "All" ? null : activeTab._id,
        pageType: "bss",
      };
      const response = await FolderServices.moveToFolders(payload, authToken);
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: "Users Moved successfully",
          showConfirmButton: false,
          timer: 2500,
        });
        getAllBssForms();
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while moving to folders", err);
    }
  };
  const moveToArchive = () => {
    const archiveId = folders.filter((folder) => folder.title == "Archive")[0]
      ?._id;
    if (archiveId) {
      moveToFolder([archiveId]);
    }
  };
  const cancelBssForm = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        purchaseIds: listOfCheckedRows.map((selected) => selected.purchaseId),
      };

      var response = await BSSServices.cancelBssForm(payload, authToken);

      if (response.success) {
        getAllBssForms();
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while canceling form", err);
    }
  };
  const updateBSSFormDate = async (date) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        bssFormIds: listOfCheckedRows.map((selected) => selected._id),
        dateOfSignature: date,
      };

      var response = await BSSServices.updateBSSFormDate(payload, authToken);
      if (response.success) {
        getAllBssForms();
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while update BSSFormDate", err);
    }
  };

  const handleChange = (field, value, userId) => {
    var updatedPageData = pageData;
    // Update the appropriate fields based on the field parameter
    updatedPageData.data = updatedPageData.data.map((userInfo) => {
      if (userInfo.userId === userId) {
        if (field === "phoneNumber") {
          userInfo.countryCode = value.countryCode;
          userInfo.contactNumber = value.number;
        } else if (field === "manualTags") {
          userInfo.userManualTags = value;
        }
      }
      return userInfo; // Return the updated or unchanged user information
    });
    var deferredResponse = defer({ res: updatedPageData });
    pageData = updatedPageData;
    setDeferredData(deferredResponse);
  };
  const handleExcelInput = (event) => {
    handlePopUpClose();
    handleExcelImport(event, (data) => {
      if (data[0]?.length >= 38) {
        const arrayOfObjects = createArrayOfObjects(data);

        if (arrayOfObjects.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Rows has missing value",
            showConfirmButton: false,
            timer: 2500,
          });
          return;
        } else {
          // Send data to backend
          createBssForms(arrayOfObjects);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Number of headers are not right",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
  };
  const createArrayOfObjects = (data) => {
    const columnNames = data.slice(0, 38); // Extract column names from the first row
    const rowData = data.slice(1); // Extract data rows (excluding the first row)

    const arrayOfObjects = rowData.map((row, rowIndex) => {
      if (row.length < 36) {
        console.error(
          `Error: Row ${rowIndex + 1} has fewer elements than column names.`
        );
        return null; // Return null to indicate an error
      }
      const obj = {};
      obj.courseId = "";

      excelSheetHeaders.forEach((header, index) => {
        var data = rowData[rowIndex][index];
        if (
          index === 30 ||
          index === 34 ||
          index === 35 ||
          index === 36 ||
          (index === 38 && data)
        ) {
          // if(data.includes('file')){
          // 	data = "https://lh3.googleusercontent.com/d/"+data.split("/file/d/")[1]
          // }
          // else if (data.includes("open")){

          if (data && data.includes("/open?id=")) {
            data =
              "https://lh3.googleusercontent.com/d/" +
              data.split("/open?id=")[1];
          }

          if (data && data.includes("file/d/")) {
            data =
              "https://lh3.googleusercontent.com/d/" + data.split("file/d/")[1];
          }
          // }
          // data = "https://drive.google.com/thumbnail?id="+data.split("/open?id=")[1]
          // data = "https://drive.usercontent.google.com/download?id="+data.split("/open?id=")[1]
          // data = "https://drive.lienuc.com/uc?id="+data.split("/open?id=")[1]
        } else if (index === 33 || index === 31) {
          if (typeof data === "number") {
            data = moment("1899-12-30")
              .add(data + 1, "days")
              .toISOString();
          } else {
            data = moment(data, "MM/DD/YYYY").toISOString();
          }
        }

        obj[header] = data;
      });
      return obj;
    });

    // Filter out null values (rows with errors) from the array
    const filteredArrayOfObjects = arrayOfObjects.filter((obj) => obj !== null);

    return filteredArrayOfObjects;
  };

  const showThumbnails = () => {
    setIsThumbnailVisible(!isThumbnailVisible);
    handlePopUpClose();
  };
  const createBssForms = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = { usersList: data };
      var response = await BSSServices.createBssForms(payload, authToken);

      if (response.success) {
        getAllBssForms();
        setUndoPurchaseList(response.data.map((purchase) => purchase._id));
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while changing the course", err);
    }
  };
  const handleUndo = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = { purchaseIds: undoPurchaseList };

      var response = await BSSServices.undoBssForms(payload, authToken);

      if (response.success) {
        getAllBssForms();
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while changing the course", err);
    }
  };

  const handleTabClick = (tab) => {
    props.updateTab(tab);
    navigate(".", { replace: true });
  };
  useEffect(() => {
    setActiveTab(props.tab);
  }, [props.tab]);

  return (
    <>
      {props?.userId === undefined ? <SidebarNavigation /> : ""}
      <BSSView
        deferredData={deferredData}
        disableBulkActionBtn={disableBulkActionBtn}
        handleSearch={handleSearch}
        searchText={searchText}
        folders={folders}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        handleCheckBoxes={handleCheckBoxes}
        handleSort={handleSort}
        handlePdf={handlePdf}
        // Fullscreen
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        //ColumnButton and popup
        columnsOptionPopUpRef={columnsOptionPopUpRef}
        handleColumnBtnClick={handleColumnBtnClick}
        BSSFormRef={BSSFormRef}
        pdfData={pdfData}
        // Start: Control Pop Up
        popUp={popUp}
        popUpPosition={popUpPosition}
        isPopUpVisible={isPopUpVisible}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        allCourses={allCourses}
        allUnderGraduateDegrees={allUnderGraduateDegrees}
        allInstitute={allInstitute}
        moveToArchive={moveToArchive}
        listOfCheckedRows={listOfCheckedRows}
        cancelBssForm={cancelBssForm}
        formDate={formDate}
        handleFormDate={setFormDate}
        handleChange={handleChange}
        excelFileInputRef={excelFileInputRef}
        handleExcelInput={handleExcelInput}
        isThumbnailVisible={isThumbnailVisible}
        showThumbnails={showThumbnails}
        handleUndo={handleUndo}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tab: state.tabs.BSSTab,
    access: state.login.access,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateTab: TabsActions.updateBSSTab,
      updateTags: TagsActions.updateTags,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BSS);
