import React, { useState, useEffect } from 'react';
import BssProofView from './view';

import * as VerificationServices from '@services/Verification';
import * as BssServices from '@services/BSS';
import * as VerificationActions from '@redux/actions/Verification';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { decryptText } from '@library/enc-dec';
import Swal from 'sweetalert2';


function PhotoProof(props) {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [iscardVisible, setIscardVisible] = useState(false);
    const [selectedProof, setSelectedProof] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isMenuVisible, setIsMenuVisible] = useState(false)
	const [fromInputDate, setFromInputDate] = useState('');
	const [toInputDate, setToInputDate] = useState('');
	const [errorText, setErrorText] =useState('');

	let authToken = decryptText(localStorage.getItem('aEmediat'));

	useEffect(() => {
		getPendingBssProof();
	}, [fromInputDate, toInputDate]);

	// Get All the verification data from API service 
	const getPendingBssProof = async () => {
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		let payload = {pageNo : 0,
			limit : 1000000,
			isSorted: false,
			sortBy : 'createdAt',
			searchText : '',
			folderType: 'All',
			pageType: 'verification',
			startDate:'',
			endDate:'',
			userId:''
		};

		let response = await BssServices.getAllBssForms(payload, authToken);
		// let response = await VerificationServices.getPendingBssProof(payload, authToken);
		if (response.success) {
			props.updatePendingPhotoProof([...response.data]);
			return;
		}
		alert(response.message);
	};

	const verifyBSSProof = async(rejectionList, verificationType)=>{
		const payload= {"usersList":[{
			"bssFields": {
				"guardianNameStatus": "Verified",
				"underGraduateDegreeStatus": "Verified",
				"underGraduateInstituteNameStatus": "Verified",
				"underGraduateInstituteAddressStatus": "Verified",
				"registrationNumberStatus": "Verified",
				"passingYearStatus": "Verified",
				"signatureVerificationStatus": "Verified",
				"aadhaarVerificationStatus": "Verified",
				"panVerificationStatus": "Verified",
				"otherDocsVerificationStatus": "Verified",
				"placeOfSignatureStatus": "Verified"
			},
			"userFields": {
				"fullNameStatus": "Verified",
				"passportPhotoStatus": "Verified"
			},
			"bssFormId": selectedProof._id,
			"userId": selectedProof.userId
		}]
	}
		if(verificationType==='Verified' && rejectionList.length >0)
		{
			setErrorText('Rejection list should be empty to verify a form.')
			return
		}else{
			if(verificationType==='Rejected' && rejectionList.length ===0)
			{
				setErrorText('Select at least one option to reject the form.')
				return
			}

			rejectionList = rejectionList.map(reason=>reason.title)
			payload.usersList[0].bssFields = {
				"guardianNameStatus": rejectionList.includes('guardianName') ? "Rejected":"Verified",
				"underGraduateDegreeStatus": rejectionList.includes("underGraduateDegree") ? "Rejected":"Verified",
				"underGraduateInstituteNameStatus": rejectionList.includes("underGraduateInstituteName") ? "Rejected":"Verified",
				"underGraduateInstituteAddressStatus": rejectionList.includes('guardianName') ? "Rejected":"Verified",
				"registrationNumberStatus": rejectionList.includes("registrationNumber") ? "Rejected":"Verified",
				"passingYearStatus": rejectionList.includes("passingYear") ? "Rejected":"Verified",
				"signatureVerificationStatus": rejectionList.includes("signature") ? "Rejected":"Verified",
				"aadhaarVerificationStatus": rejectionList.includes("aadhaarCard") ? "Rejected":"Verified",
				"panVerificationStatus": rejectionList.includes("panCard") ? "Rejected":"Verified",
				"otherDocsVerificationStatus": rejectionList.includes("otherDocs") ? "Rejected":"Verified",
				"placeOfSignatureStatus":  rejectionList.includes("placeOfSignature") ? "Rejected":"Verified",
			}
			payload.usersList[0].userFields= {
				"fullNameStatus": rejectionList.includes('fullName') ? "Rejected":"Verified",
				"passportPhotoStatus": rejectionList.includes('passportPhoto') ? "Rejected":"Verified",
			}
		}
		closeVerificationPopUp()
		let response = await VerificationServices.verifyBssForm(payload, authToken)
	    if (response.success){
			getPendingBssProof();
			Swal.fire({icon: "success",title: response.message,showConfirmButton: false,timer: 2500});
		}else{
		Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
		}
	}

	const verifyMultiplePhotoProof = async(userIdList, verificationType)=>{
		if(selectedUsers.length===0)
		{
			Swal.fire({icon: "warning",title: "No User selected",showConfirmButton: false,timer: 2500});
			return
		}

		const userList = userIdList.map((obj, index)=>{
			return {
				"bssFields": {
					"guardianNameStatus": verificationType,
					"underGraduateDegreeStatus": verificationType,
					"underGraduateInstituteNameStatus": verificationType,
					"underGraduateInstituteAddressStatus": verificationType,
					"registrationNumberStatus": verificationType,
					"passingYearStatus": verificationType,
					"signatureVerificationStatus": verificationType,
					"aadhaarVerificationStatus": verificationType,
					"panVerificationStatus": verificationType,
					"otherDocsVerificationStatus": verificationType,
					"placeOfSignatureStatus": verificationType
				},
				"userFields": {
					"fullNameStatus": verificationType,
					"passportPhotoStatus": verificationType
				},
				"bssFormId": obj._id,
				"userId": obj.userId
			}
		})
		const payload= {"usersList":userList}

		let response = await VerificationServices.verifyBssForm(payload, authToken)
	    if (response.success){
			getPendingBssProof();
			Swal.fire({icon: "success",title: response.message,showConfirmButton: false,timer: 2500});
		}else{
		Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
		}
	}


	////////////////////////////////////////////////////////////////////////////////////////////
	const updateSelectedUsers = (_id, userId)=>{
		let updatedUsers = [...selectedUsers]
		 const index = updatedUsers.findIndex(item => item._id === _id);

		 if (index !== -1) {
			 updatedUsers.splice(index, 1);
		 } else {
			 updatedUsers.push({_id, userId});
		 }
		setSelectedUsers(updatedUsers) 
	 }
 
	 const selectAllDocuments = () =>{ 
 
		 if( ! isAllSelected)
		 {
			let allUserIds = []
			 props.pendingPhotoProof.forEach(proof => {
				 allUserIds.push({"_id":proof._id , "userId": proof.userId})
			 });
 
			 setSelectedUsers(allUserIds)
		 }
		 else{
			 setSelectedUsers([])
		 }
 
		setIsAllSelected(!isAllSelected)
	 }
 
	 const openVerificationPopUp =(idProof)=>{
		 setSelectedProof(idProof)
		 setIscardVisible(true)
	 }
 
	 const closeVerificationPopUp =()=>{
	 setIscardVisible(false)
	 setErrorText('')
	 }

	 const handleMenuVisibility=(type)=>{
		setIsMenuVisible(!isMenuVisible)
		if(type=='main' || type== undefined){
			return
		}
		let date = new Date();
		let firstDay = ''
		let lastDay = ''
		if(type=="today"){
		   firstDay = date;
		   lastDay = firstDay;
			setFromInputDate(firstDay.toISOString().substring(0, 10));
			setToInputDate(lastDay.toISOString().substring(0, 10));

		}else if(type=="month"){
			firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
			lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
			setFromInputDate(firstDay.toISOString().substring(0, 10));
			setToInputDate(lastDay.toISOString().substring(0, 10));
		}
		else if(type=="week"){
			var curr = new Date; // get current date
			var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
			var last = first + 6; // last day is the first day + 6

			firstDay = new Date(curr.setDate(first));
			lastDay = new Date(curr.setDate(last));
			setFromInputDate(firstDay.toISOString().substring(0, 10));
			setToInputDate(lastDay.toISOString().substring(0, 10));

		}
		else if(type=="year"){
			firstDay = new Date(date.getFullYear(), 0, 2);
			lastDay = new Date(date.getFullYear(), 12, 1);
			setFromInputDate(firstDay.toISOString().substring(0, 10));
			setToInputDate(lastDay.toISOString().substring(0, 10));
		}
		else if(type=="all"){
		   setFromInputDate('');
		   setToInputDate('');
		} 
	}
	
	return (
		<>
			<BssProofView
			  popUpImg = {selectedProof.passportPhoto}
				popUpName = {selectedProof.fullName}
				popUpContact = {selectedProof.countryCode +" "+selectedProof.contactNumber}
				popUpWhatsapp = {selectedProof.whatsAppCountryCode +" "+ selectedProof.whatsAppNumber}
				popUpEmail = {selectedProof.email}
				popUpQualification={selectedProof.qualification}
				popUpCourses = {selectedProof.courses}
				selectedProof = {selectedProof}
				handlePopUpVerifyButton = {(rejectionList)=>{verifyBSSProof(rejectionList,'Verified')}}
				handlePopUpRejectButton = {(rejectionList)=>{verifyBSSProof(rejectionList,'Rejected')}}
				
				handlePopUpPopUpCheckBox = {updateSelectedUsers}
				handlePopUpBackButton = {()=>closeVerificationPopUp()}
				isPopUpVisible = {iscardVisible}
				isPopUpChecked = { selectedUsers.some(card => card._id === selectedProof._id) }
  
				cardGridData = {props.pendingPhotoProof}
				selectedCards= {selectedUsers}
				handleGridCardClick = {(data)=>{openVerificationPopUp(data)}}
				handleGridCheckBox = {updateSelectedUsers}
  
				isFilterChecked = {isAllSelected}
				handleFilterCheckbox = {()=>{selectAllDocuments()}} 
				handleVerifyAllButton = {()=>{verifyMultiplePhotoProof(selectedUsers, 'Verified')}}
				handleRejectAllButton = {()=>{verifyMultiplePhotoProof(selectedUsers, 'Rejected')}}
				
				isFilterMenuVisible = {isMenuVisible}
				handleFilterMenuVisibility = {(option)=>{handleMenuVisibility(option)}}
				toggleFullscreen={props.toggleFullscreen}

				errorText={errorText}
			  />
		</>
	);
}



const mapStateToProps = (state) => {
	return {
		pendingPhotoProof: state.verification.pendingPhotoProof
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updatePendingPhotoProof: VerificationActions.updatePendingPhotoProof
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoProof);

