export const getAllOrders = async (payload, authToken, signal=null) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/getAllOrders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
      signal
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Video services : getAllOrders() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const deleteOrders = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteOrders`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Video services : deleteOrders()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
