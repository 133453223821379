import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import alertIcon from "@assets/common/alertIcon.png";
import UploadImgPlaceHolder from "@assets/common/uploadImgPlaceHolder.png";
import DeleteIcon from "@assets/common/deleteIcon.png";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import SelectInput from "@components/SelectInput";
import SelectionPopUp from "@components/SelectionPopUp";
import PopUp from "@components/PopUp";
import ImageInput from "@components/ImageInput";

import { decryptText } from "@library/enc-dec";
import { pages } from "@pages/SuperAdmin/AdminUsers/Main/data";
import User from "@components/User";

function ProfileView(props) {
  return (
    <>
      <div
        className="rightHandRightPageMainContainer userPageMainContainer row"
        style={{ maxWidth: "1200px" }}
      >
        <div className="col-9">
          <h4 className="heading">User Details</h4>
          <div className="userProfileContentMainContainer">
            <Accordion
              defaultExpanded
              style={{ border: "none", boxShadow: "none" }}
            >
              <AccordionSummary
                style={{ flexDirection: "row-reverse", gap: "20px" }}
              >
                <b
                  style={{
                    fontSize: "17px",
                    fontFamily: "inter",
                    fontWeight: "500",
                  }}
                >
                  Basic Information
                </b>
                <div className="topContainer2 ms-auto">
                  <div>
                    <Link
                      style={{ textDecoration: "none" }}
                      className="cancelBtn"
                      to="/superAdmin"
                    >
                      Cancel
                    </Link>

                    <button onClick={props.handleSave} className="saveBtn">
                      Save
                    </button>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {/* START : Personal Section */}
                <div className="formMainContainer">
                  {/* START : Username */}
                  <div className="formRow">
                    <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                      Name
                    </p>
                    <input
                      className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                      type="text"
                      name="fullName"
                      value={props.userDetails.fullName}
                      onChange={(event) => {
                        props.handleChange(event.target.value, "fullName");
                      }}
                      placeholder="Enter name"
                    />
                  </div>
                  {/* END : Username */}

                  {/* START : Display Name */}
                  <div className="formRow">
                    <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                      User Name
                    </p>
                    <input
                      className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                      type="text"
                      name="lastName"
                      value={props.userDetails.username}
                      onChange={(event) => {
                        props.handleChange(event.target.value, "username");
                      }}
                      placeholder="Enter user name"
                    />
                  </div>

                  {/* START : Display Name */}
                  <div className="formRow">
                    <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                      Password
                    </p>
                    <input
                      className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                      type="text"
                      name="lastName"
                      value={props.userDetails.password}
                      onChange={(event) => {
                        props.handleChange(event.target.value, "password");
                      }}
                      placeholder="Enter password"
                    />
                  </div>
                </div>
                {/* END : Personal Section */}
              </AccordionDetails>
            </Accordion>
            <div className="d-flex flex-column px-3 gap-4">
              <SelectInput
                title={"Page rights"}
                required={true}
                inputText={
                  props.userDetails?.pageRights?.length > 0
                    ? props.userDetails.pageRights.map((right) => right + ", ")
                    : "Select pages viewable to user"
                }
                onClick={(event) => {
                  props.handlePopUpOpen(event, "pageRights");
                }}
              />
              <SelectInput
                title={"User rights"}
                required={true}
                inputText={
                  props.userDetails?.userRights?.length > 0
                    ? props.userDetails.userRights.map((right) => {
                        return right?.title + ", ";
                      })
                    : "Select ‘Users’ folder(s) viewable to user"
                }
                onClick={(event) => {
                  props.handlePopUpOpen(event, "userRights");
                }}
              />
              <SelectInput
                title={"BSS rights"}
                required={true}
                inputText={
                  props.userDetails?.bssRights?.length > 0
                    ? props.userDetails.bssRights.map(
                        (right) => right?.title + ", "
                      )
                    : "Select ‘BSS’ folder(s) viewable to user"
                }
                onClick={(event) => {
                  props.handlePopUpOpen(event, "bssRights");
                }}
              />
              <SelectInput
                title={"Lead rights"}
                required={true}
                inputText={
                  props.userDetails?.leadsRights?.length > 0
                    ? props.userDetails.leadsRights?.map(
                        (right) => right?.title + ", "
                      )
                    : "Select ‘Leads’ folder(s) viewable to user"
                }
                onClick={(event) => {
                  props.handlePopUpOpen(event, "leadsRights");
                }}
              />
              {/* <SelectInput title={'Customer care rights'}
								required={true}
								inputText={props.userDetails?.customerCareRights?.length>0?
									props.userDetails.customerCareRights.map((right)=> right.title+", "):
									"Select ‘Customer care’ folder(s) viewable to user"}
									onClick={(event)=>{props.handlePopUpOpen(event, 'customerCareRights')}}
							/> */}
              <div className="visibilityContainer">
                <p style={{ fontWeight: "700" }}>
                  Role<span style={{ color: "red" }}>*</span>
                </p>
                <div className="form-check">
                  <input
                    onChange={(event) => props.handleChange("admin", "role")}
                    checked={props.userDetails.role === "admin"}
                    value="unlisted"
                    className="form-check-input"
                    type="radio"
                    id="unlisted"
                  />
                  <label htmlFor="unlisted">Admin</label>
                </div>
                <div className="form-check">
                  <input
                    onChange={(event) => props.handleChange("standard", "role")}
                    checked={props.userDetails.role === "standard"}
                    value="public"
                    className="form-check-input"
                    type="radio"
                    id="public"
                  />
                  <label htmlFor="public">
                    Standard user
                    <div style={{ color: "red" }}>
                      Needs approval from admin for sensitive tasks
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>

        {/* Start : Upload img container */}
        <div className="col-3">
          <div className="userProfileImgMainContainer">
            {/* <img
							
							src={props.userDetails.displayPhoto
								? props.userDetails.displayPhoto
									: UploadImgPlaceHolder
							}
							className='uploadIcon'
							alt='UploadImgPlaceHolder'
						/> */}
            <div
              id="profileImage_uploadBtn"
              onClick={(e) => props.handlePopUpOpen(e, "displayPhoto")}
            >
              <User
                imageText={props.userDetails?.fullName}
                image={props.userDetails?.displayPhoto}
                size={"180px"}
                fontSize={"40px"}
              />
            </div>

            <img
              onClick={(event) => {
                props.handleChange("", "displayPhoto");
              }}
              className="deleteIcon"
              src={DeleteIcon}
              alt="DeleteIcon"
            />
          </div>
          <div className="text-center">
            <span style={{ color: "#898989" }}>Display Picture</span>
          </div>
        </div>

        {/* END : Upload img container */}

        {/* START : Show Doc img popup */}
        <button
          style={{ display: "none" }}
          id="docPopupBtn"
          data-bs-toggle="modal"
          data-bs-target="#docModal"
        >
          Launch demo modal
        </button>
        <div className="modal fade" id="docModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="docModalLabel">
                  Modal title
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body text-center">
                <img
                  id="docImg"
                  src={alertIcon}
                  alt="img"
                  style={{ width: "-webkit-fill-available" }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* END : Show Doc img popup */}
      </div>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "pageRights"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <SelectionPopUp
          data={pages}
          optionKey={"name"}
          title={""}
          searchPlaceholder={"Search for page"}
          isSearchEnabled={true}
          height={"310px"}
          width={"285px"}
          selectionType={"multiple"}
          selectedItems={pages?.filter((obj) =>
            props?.userDetails?.pageRights?.includes(obj.name)
          )}
          handleCancel={props.handlePopUpClose}
          handleSave={props.handlePopUpSave}
        />
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "userRights"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <SelectionPopUp
          data={props.allFolders.map((folder) =>
            folder.pageType === "user" && folder.title !== "Archive"
              ? folder
              : ""
          )}
          optionKey={"title"}
          title={""}
          searchPlaceholder={"Search for 'User' folder"}
          isSearchEnabled={true}
          height={"310px"}
          width={"285px"}
          selectedItems={props.userDetails?.userRights}
          dataKey={"_id"}
          selectedItemsKey={"_id"}
          selectionType={"multiple"}
          handleCancel={props.handlePopUpClose}
          handleSave={props.handlePopUpSave}
        />
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "bssRights"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <SelectionPopUp
          data={props.allFolders.map((folder) =>
            folder.pageType === "bss" && folder.title !== "Archive"
              ? folder
              : ""
          )}
          optionKey={"title"}
          title={""}
          searchPlaceholder={"Search for 'BSS' folder"}
          isSearchEnabled={true}
          height={"310px"}
          width={"285px"}
          selectedItems={props.userDetails?.bssRights}
          dataKey={"_id"}
          selectedItemsKey={"_id"}
          selectionType={"multiple"}
          handleCancel={props.handlePopUpClose}
          handleSave={props.handlePopUpSave}
        />
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "leadsRights"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <SelectionPopUp
          data={props.allFolders.map((folder) =>
            folder.pageType === "leads" && folder.title !== "Archive"
              ? folder
              : ""
          )}
          optionKey={"title"}
          title={""}
          searchPlaceholder={"Search for 'Leads' folder"}
          isSearchEnabled={true}
          height={"310px"}
          width={"285px"}
          selectedItems={props.userDetails?.leadsRights}
          dataKey={"_id"}
          selectedItemsKey={"_id"}
          selectionType={"multiple"}
          handleCancel={props.handlePopUpClose}
          handleSave={props.handlePopUpSave}
        />
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "customerCareRights"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <SelectionPopUp
          data={props.allFolders.map((folder) =>
            folder.pageType === "customerCare" && folder.title !== "Archive"
              ? folder
              : ""
          )}
          optionKey={"title"}
          title={""}
          searchPlaceholder={"Search for 'Customer care' folder"}
          isSearchEnabled={true}
          height={"310px"}
          width={"285px"}
          selectedItems={props.userDetails?.customerCareRights}
          dataKey={"_id"}
          selectedItemsKey={"_id"}
          selectionType={"multiple"}
          handleCancel={props.handlePopUpClose}
          handleSave={props.handlePopUpSave}
        />
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp === "displayPhoto"}
        top={"50px"}
        left={"50px"}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div className="full-page-overlay">
          <ImageInput
            handleClose={props.handlePopUpClose}
            handleAdd={(event, files) => props.handlePopUpSave(files[0])}
          />
        </div>
      </PopUp>
    </>
  );
}

export default ProfileView;
