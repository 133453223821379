import moment from "moment";

import * as TagServices from "@services/Tags";
import { decryptText } from "@library/enc-dec";

export const getEventElementPosition = (event) => {
  let currentEle = event.target;
  let left = currentEle.getBoundingClientRect().left;
  let top = window.scrollY + currentEle.getBoundingClientRect().bottom;
  return { left: left, top: top };
};

export const toggleFullscreen = (fullscreenElementRef) => {
  const elem = fullscreenElementRef.current;

  if (
    elem &&
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
};

export const convertToISOFormat = (dateString) => {
  // Create a Moment.js object with the specified date
  const date = moment(dateString);

  // Set the time to 18:59:30.472 and adjust to UTC timezone
  date.set({ hour: 18, minute: 59, second: 30, millisecond: 472 }).utc();

  // Format the date to the desired format
  return date.toISOString();
};

export const getAllTags = async (tagType) => {
  try {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    let payload = {
      pageNo: 0,
      limit: 99999,
      isSorted: false,
      sortBy: "title",
      searchText: "",
      visibility: "active",
      //   tagType: userId ? "manual" : "customerCare",
      tagType: tagType,
    };

    var response = await TagServices.getAllTags(payload, authToken);
    if (response.success) {
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log("Error coming while getting all Tags", err);
  }
};

export const debounce = (func, delay) =>{
  let timeoutId;

  return function(...args) {
    // Clear the previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout to call the function after the specified delay
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}