export const searchAllCourseVideos = async (payload, authToken) => {
	const {pageNo, limit, sortBy, sort, searchText} =payload
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/searchAllCourseVideos?pageNo=${pageNo}&limit=${limit}&searchText=${searchText}&sortBy=${sortBy}&isSorted=${sort}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Video services : searchAllCourseVideos() ',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getAllCourseVideos = async (payload, authToken, signal=null) => {
	const {pageNo, limit, sortBy, isSorted, searchText} =payload
	try {
		const url = `${process.env.REACT_APP_BASE_URL}/getAllCourseVideos?pageNo=${pageNo}&limit=${limit}${searchText? '&searchText='+searchText: ''}${sortBy? '&sortBy='+sortBy: ''}${isSorted? '&isSorted='+isSorted: ''}`
		return await fetch(url
			,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				signal
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Video services : getAllCourseVideos() ',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateVideoVisibility = async (payload, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updateVideoVisibility`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Video services : updateVideoVisibility()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateVideoThumbnailAndTitle = async (payload, authToken) => {
	try {
		let formData = new FormData();
		for (let key in payload) {
			formData.append(key, payload[key]);
		}
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updateVideoThumbnailAndTitle`,
			{
				method: 'PATCH',
				headers: {
					Authorization: authToken,
				},
				body: formData,
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Video services : updateThumbnailAndTitle()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getVideo = async (videoDocId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getVideo/${videoDocId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Videos services: getVideo() ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateVideo = async (payload, authToken) => {
	try {
		let formData = new FormData();
		for (let key in payload) {
			if (key === 'additionalInfo') {
				formData.append(key, JSON.stringify(payload[key]));
			} else {
				formData.append(key, payload[key]);
			}
		}

		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateVideo`, {
			method: 'PATCH',
			headers: {
				Authorization: authToken,
			},
			body: formData,
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Video services : updateVideo()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addVideo = async (payload, authToken) => {
	try {
		let formData = new FormData();
		for (let key in payload) {
			if (key === 'additionalInfo') {
				formData.append(key, JSON.stringify(payload[key]));
			} else {
				formData.append(key, payload[key]);
			}
		}

		return await fetch(`${process.env.REACT_APP_BASE_URL}/addVideo`, {
			method: 'POST',
			headers: {
				Authorization: authToken,
			},
			body: formData,
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Video services : addVideo()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const deleteVideos = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteVideos`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Video services : deleteVideos()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getHistoryVideos = async (userId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getHistoryVideos/${userId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Video services : getHistoryVideos()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
