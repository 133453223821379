import React, { useState, useEffect, useRef } from "react";
import { defer, useLocation } from "react-router-dom";

import UndergraduateDegreeView from "./view";
import * as UndergraduateDegreeServices from "@services/UndergraduateDegree";
import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";
import { getEventElementPosition, toggleFullscreen } from "@helpers/common.js";
import { debounce } from "../../../helpers/common";
var listOfCheckedRows = [];

function UndergraduateDegree(props) {
  const fullscreenElementRef = useRef(null);
  const columnsOptionPopUpRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnsOptionPopUpRef.current &&
        !columnsOptionPopUpRef.current.contains(event.target)
      ) {
        columnsOptionPopUpRef.current.style.display = "none";
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [deferredData, setDeferredData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isSorted, setIsSorted] = useState(false);
  const [sortBy, setSortBy] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  var page = Number(queryParams.get("page"));

  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);

  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});

  const [selectedRow, setSelectedRow] = useState(null);
  const [allUndergraduateDegree, setAllUndergraduateDegree] = useState([]);

  useEffect(() => {
    if(limit>0){
    debounce(getAllUndergraduateDegree, 200)();
    }
  }, [page, limit, isSorted, sortBy, searchText]);

  const getAllUndergraduateDegree = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: page,
        limit: limit,
        isSorted: "true",
        sortBy: "createdAt",
        searchText: searchText,
        visibility: "all",
      };

      var deferredResponse = defer({
        res: UndergraduateDegreeServices.getAllUGDegrees(payload, authToken),
      });
      setDeferredData(deferredResponse);
      deferredResponse.data.res
        .then((response) => {
          setAllUndergraduateDegree(response);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    } catch (err) {
      console.log("Error coming while getting all UndergraduateDegree", err);
    }
  };

  const handleSearch = (data) => {
    setSearchText(data.text);
    page = 0;
  };

  //Fn to check and uncheck all checkboxes
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedRows = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };

  // To sort
  const handleSort = (data) => {
    const mapping = { "Date Added": "createdAt", "Date Modified": "updatedAt" };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    setSortBy(id);
    setIsSorted(data[0]?.desc ? data[0].desc : false);
  };

  const handleColumnBtnClick = (event) => {
    handlePopUpClose();
    columnsOptionPopUpRef.current.style.display = "block";
    var position = getEventElementPosition(event);
    columnsOptionPopUpRef.current.style.top = position.top + "px";
    columnsOptionPopUpRef.current.style.left = position.left - 160 + "px";
    event.stopPropagation();
  };

  // To close the popup container
  const handlePopUpOpen = (event, popUpName, data) => {
    setSelectedRow(data);
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    columnsOptionPopUpRef.current.style.display = "none";
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async (data) => {
    if (popUp === "createNew") {
      try {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        let payload = {
          degreeId: selectedRow ? selectedRow._id : null,
          title: data.title,
        };
        const response = await UndergraduateDegreeServices.updateUGDegree(
          payload,
          authToken
        );
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } catch (err) {
        console.log(
          "Error coming while creating/updating UndergraduateDegree",
          err
        );
      }
    } else if (popUp === "visibility") {
      try {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        let payload = {
          degreeIds: [selectedRow._id],
          visibility: data[0].visibility,
        };
        const response =
          await UndergraduateDegreeServices.updateUGDegreeVisibility(
            payload,
            authToken
          );
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } catch (err) {
        console.log("Error coming while updating folder visibility", err);
      }
    }
    getAllUndergraduateDegree();
    handlePopUpClose();
  };

  return (
    <>
      <UndergraduateDegreeView
        deferredData={deferredData}
        disableBulkActionBtn={disableBulkActionBtn}
        handleSearch={handleSearch}
        searchText={searchText}
        handleCheckBoxes={handleCheckBoxes}
        handleSort={handleSort}
        // Fullscreen
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        //ColumnButton and popup
        columnsOptionPopUpRef={columnsOptionPopUpRef}
        handleColumnBtnClick={handleColumnBtnClick}
        // Start: Control Pop Up
        selectedRow={selectedRow}
        popUp={popUp}
        popUpPosition={popUpPosition}
        isPopUpVisible={isPopUpVisible}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
      />
    </>
  );
}

export default UndergraduateDegree;
