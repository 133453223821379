export const getAllBssForms = async (payload, authToken, signal=null) => {
  try {
    const {
      pageNo,
      limit,
      isSorted,
      sortBy,
      searchText,
      folderType,
      pageType,
      userId,
      startDate,
      endDate,
    } = payload;
    const url = `${
      process.env.REACT_APP_BASE_URL
    }/getAllBssForms?pageType=${pageType}&folderType=${folderType}&pageNo=${pageNo}&limit=${limit}&searchText=${searchText}${
      sortBy ? "&sortBy=" + sortBy : ""
    }${
      isSorted ? "&isSorted=" + isSorted : ""
    }&userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
    return await fetch(url, {
      method: "GET",
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: authToken,
      },
      signal
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Courses : Get all BSS forms ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getAllPendingBssForms = async (payload, authToken) => {
  try {
    const {
      pageNo,
      limit,
      isSorted,
      sortBy,
      searchText,
      folderType,
      pageType,
      userId,
      startDate,
      endDate,
    } = payload;
    const url = `${
      process.env.REACT_APP_BASE_URL
    }/getAllPendingBssForms?pageType=${pageType}&folderType=${folderType}&pageNo=${pageNo}&limit=${limit}&searchText=${searchText}${
      sortBy ? "&sortBy=" + sortBy : ""
    }${
      isSorted ? "&isSorted=" + isSorted : ""
    }&userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
    return await fetch(url, {
      method: "GET",
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Courses : Get all pending BSS forms ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getAllInstitutes = async (payload, authToken) => {
  // Visibility-> active,disabled,"all"
  const { pageNo, limit, isSorted, sortBy, searchText, visibility } = payload;
  const url = `${
    process.env.REACT_APP_BASE_URL
  }/getAllInstitutes?pageNo=${pageNo}&limit=${limit}&visibility=${visibility}${
    searchText ? "&searchText=" + searchText : ""
  }${sortBy ? "&sortBy=" + sortBy : ""}${
    isSorted ? "&isSorted=" + isSorted : ""
  }`;

  try {
    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : getAllInstitutes() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateInstitute = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateInstitute`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : updateInstitute()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateInstitutesVisibility = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateInstitutesVisibility`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : updateInstitutesVisibility()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const deleteInstitutes = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteInstitutes`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : deleteInstitutes()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getAllBssCourses = async (payload, authToken) => {
  // Visibility-> active,disabled,"all"
  const { pageNo, limit, isSorted, sortBy, searchText, visibility } = payload;
  const url = `${
    process.env.REACT_APP_BASE_URL
  }/getAllBssCourses?pageNo=${pageNo}&limit=${limit}&visibility=${visibility}${
    searchText ? "&searchText=" + searchText : ""
  }${sortBy ? "&sortBy=" + sortBy : ""}${
    isSorted ? "&isSorted=" + isSorted : ""
  }`;

  try {
    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : getAllBssCourses() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateBssCourse = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateBssCourse`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : updateBssCourse()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateBssCoursesVisibility = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateBssCoursesVisibility`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : updateBssCoursesVisibility()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const deleteBssCourses = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteBssCourses`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : deleteBssCourses()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

// BSS Grid popup related API
export const updateBssCourseDetails = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateBssCourseDetails`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : updateBssCourseDetails()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
export const updateBssExaminationYear = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateBssExaminationYear`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : updateBssExaminationYear()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateBssCourseDuration = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateBssCourseDuration`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : updateBssCourseDuration()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateBssUnderGraduateDegree = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateBssUnderGraduateDegree`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : updateBssUnderGraduateDegree()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateBssInstituteId = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateBssInstituteId`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : updateBssInstituteId()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
export const cancelBssForm = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/cancelBssForm`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from BSS services : cancelBssForm()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
export const updateBSSFormDate = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateBSSFormDate`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from BSS services : updateBSSFormDate()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const createBssForms = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/createBssForms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from BSS services : createBssForms()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const undoBssForms = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/undoBssForms`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from BSS services : undoBssForms()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
