import React, { useEffect, useState, useRef } from "react";
import SeriesView from "./view";
import {
  useNavigate,
  defer,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as SeriesServices from "@services/Series";
import * as SeriesActions from "@redux/actions/Series";

import { navigationData } from "./data";
import { decryptText } from "@library/enc-dec";
import { produce } from "immer";

import { getEventElementPosition, toggleFullscreen } from "@helpers/common.js";

import Swal from "sweetalert2";
var listOfCheckedSeries = [];
var visibilityCourseList = [];

function Series(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const fullscreenElementRef = useRef(null);
  const [deferedData, setDeferedData] = useState({});
  const [allSeries, setAllSeries] = useState([]);

  const [isSorted, setIsSorted] = useState(props.filterInformation.isSorted);
  const [sortBy, setSortBy] = useState(props.filterInformation.sortBy);
  const [showThumbnail, setShowThumbnail] = useState(true);

  // UseState's to control the functionality of setting visibility of courses
  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);

  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  const [selectedSeries, setSelectedSeries] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  const controller = useRef(new AbortController())
  const [pageLoaded, setPageLoaded] = useState(false);
  
  useEffect(()=>{
    if(props.isSelected)
    {
      setPageLoaded(true)
      setSearchParams({
        page: props.filterInformation.page,
        limit: props.filterInformation.limit,
      });
    }
    return ()=>{
      controller.current.abort()
    }
  },[props.isSelected])

  useEffect(() => {
    if (!Number(queryParams.get("limit")) && Number(queryParams.get("page"))) {
      return;
    }
    if(props.isSelected && Number(queryParams.get('limit')>0) &&
      !(Number(queryParams.get("limit")) == props.filterInformation.limit 
       &&
       Number(queryParams.get("page")) == props.filterInformation.page 
      )
    ){
    props.updateFilterInformation({
      page: Number(queryParams.get("page")),
      limit: Number(queryParams.get("limit")),
      sortBy,
      isSorted,
      searchText: props.filterInformation.searchText,
    });
  }
  }, [
    Number(queryParams.get("limit")),
    Number(queryParams.get("page")),
    sortBy,
    isSorted,
  ]);

  useEffect(() => {
    if(props.filterInformation.limit>0 && props.isSelected){
      getAllSeries();
    }
  }, [props.filterInformation, pageLoaded]);

  const getAllSeries = async () => {
    try {
      controller.current.abort();
      controller.current = new AbortController();
      let signal = controller.current.signal;
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: props.filterInformation.page,
        limit: props.filterInformation.limit,
        sortBy: props.filterInformation.sortBy,
        isSorted: props.filterInformation.isSorted,
        searchText: props.filterInformation.searchText,
      };

      var deferedResponse = defer({
        res: SeriesServices.getAllSeries(payload, authToken, signal),
      });
      setDeferedData(deferedResponse);

      deferedResponse.data.res
        .then((response) => {
          setAllSeries(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (err) {
      console.log("Error coming while fetching all series", err);
    }
  };

  //Fn to check and uncheck all checkboxes
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedSeries = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };

  // To sort data as per date
  const handleSort = (data) => {
    const mapping = {
      "Date Added": "createdAt",
      "Last modified date": "updatedAt",
    };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    setSortBy(id);
    setIsSorted(data[0]?.desc ? data[0].desc : false);
  };

  const handleDeleteBulk = async () => {
    try {
      // let authToken = decryptText(localStorage.getItem('aEmediat'));
      // let response = await SeriesServices.deleteSeries(
      // 	{ seriesIds: listOfCheckedCourses },
      // 	authToken
      // );
      // if (response.success) {
      // 	getAllSeries();
      // 	document.getElementById('bulkCheckbox').checked = false;
      // 	setDisableBulkActionBtn(true);
      // 	Swal.fire({icon: "success", title: response.message, showConfirmButton: false,timer: 2500});
      // 	return;
      // }
      // Swal.fire({icon: "error", title: response.message, showConfirmButton: false,timer: 2500});
    } catch (err) {
      console.log("Error coming while deleting the courses", err);
    }
  };

  //Fn to redirect to course category home page
  const setDefaultNavigation = (defaultComponent) => {
    return navigationData.map((item) => {
      if (item.component === defaultComponent) {
        return { ...item, default: true };
      }
      return item;
    });
  };

  const handleRedirectToHome = (event, seriesId, defaultComponent) => {
    //Handle setting default page while navigating
    let finalNavigationData = [];
    finalNavigationData = navigationData;
    if (defaultComponent !== "none") {
      finalNavigationData = setDefaultNavigation(defaultComponent);
    } else {
      finalNavigationData = setDefaultNavigation("CourseDetails");
    }

    if (seriesId === "none") {
      navigate("/series", { state: { finalNavigationData } });
    } else {
      navigate("/series/" + seriesId, { state: { finalNavigationData } });
    }
  };

  // All Pop Up related functions
  // To close the popup container
  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
  };

  // To handleSave buttons on the popup
  const handlePopUpSave = async () => {
    handlePopUpClose();
    getAllSeries();
    setDisableBulkActionBtn(true);
    listOfCheckedSeries = [];
  };

  // Open popUps
  const handleTitleClick = (event, series) => {
    handlePopUpClose();
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    setSelectedSeries(series);
    event.stopPropagation();
  };

  const showThumbnailToggle = () => {
    setShowThumbnail((showThumbnail) => !showThumbnail);
    handlePopUpClose();
  };
  const duplicateSeries = async () => {
    if (disableBulkActionBtn) {
      return;
    }
    if (listOfCheckedSeries.length > 1) {
      Swal.fire({
        icon: "info",
        title: "Select only one series to create duplicate",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = { seriesId: listOfCheckedSeries[0].seriesId };
      let response = await SeriesServices.duplicateSeries(payload, authToken);
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        getAllSeries();
        // listOfCheckedCourses = [];
        setDisableBulkActionBtn(true);
        isPopUpVisible(false);
        return;
      }
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (err) {
      console.log("Error coming while duplicating the courses", err);
    }
  };

  const handleSearch = (data) => {
    let updatedFilters = produce(props.filterInformation, (draft) => {
      draft.page = 0;
      draft.searchText = data.text;
    });
    props.updateFilterInformation(updatedFilters);
  };

  return (
    <>
      <SeriesView
        deferedData={deferedData}
        allSeries={allSeries}
        handleRedirectToHome={handleRedirectToHome}
        handleSort={handleSort}
        searchText={props.filterInformation.searchText}
        sortBy={sortBy}
        isSorted={isSorted}
        handleSearch={handleSearch}
        // Start: Control Pop Up
        popUp={popUp}
        popUpPosition={popUpPosition}
        isPopUpVisible={isPopUpVisible}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        handleTitleClick={handleTitleClick}
        showThumbnail={showThumbnail}
        showThumbnailToggle={showThumbnailToggle}
        // END

        // Fullscreen
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        //START : UseStates & fn to control the functionality of upating visibility of video
        disableBulkActionBtn={disableBulkActionBtn}
        handleDeleteBulk={handleDeleteBulk}
        //END : Pagination useStates

        //START : UseStates & fn to control the functionality of checkboxes
        handleCheckBoxes={handleCheckBoxes}
        //END : UseStates & fn to control the functionality of checkboxes

        duplicateSeries={duplicateSeries}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    filterInformation: state.series.filterInformation,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateFilterInformation: SeriesActions.updateFilterInformation,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Series);
