import React from "react";
import moment from "moment";
import PlusIcon from "@assets/common/plusIcon.png";
import EyeIcon from "@assets/common/eye2.png";
import ExpandIcon from "@assets/userDashboard/expand.png";
import SettingIcon from "@assets/userDashboard/setting.png";
import ColumnsIcon from "@assets/userDashboard/columns.png";
import FolderIcon from "@assets/userDashboard/folder.png";
import ImportIcon from "@assets/common/download.png";
import EditPencilIcon from "@assets/common/editPencilIcon.png";
import DownIcon from "@assets/common/dropDown.png";
import "./style.css";
import BasicTableGrid from "@components/BasicTableGrid";
import DynamicTabs from "@components/DynamicTabs";
import ButtonBars from "@components/ButtonBars";
import ExamResult from "@components/ExamResult";
import PhoneNumber from "@components/PhoneNumber";
import Tags from "@components/Tags";
// Import Pop up
import PopUp from "@components/PopUp";
import AddCoursePopUp from "@components/Users/AddCoursePopUp";
import AddSeriesPopUp from "@components/Users/AddSeriesPopUp";
import AddTestPopUp from "@components/Users/AddTestPopUp";
import ConfirmArchivePopUp from "@components/Users/ConfirmArchivePopUp";
import ReplaceCoursePopUp from "@components/Users/ReplaceCoursePopUp";
import DeleteCoursePopUp from "@components/Users/DeleteCoursePopUp";
import DisableAccountPopUp from "@components/Users/DisableAccountPopUp";
import CreateBssFormsPopUp from "@components/Users/CreateBssFormsPopUp";
import RemoveUserPopUp from "@components/Users/RemoveUserPopUp";
import TestStatusPopUp from "@components/Users/TestStatusPopUp";
import EnableCoursePopUp from "@components/Users/EnableCoursePopUp";
import SelectionPopUp from "@components/SelectionPopUp";
import ResultStatusPopUp from "@components/Users/ResultStatusPopUp";
import AddRulePopUp from "@components/Users/AddRulePopUp";
import SeriesStatusPopUp from "@components/Users/SeriesStatusPopUp";
import ExamStatusPopUp from "@components/Users/ExamStatusPopUp";
import MoveToPopUp from "@components/Users/MoveToPopUp";
import EditNumberPopUp from "@components/EditNumberPopUp";
import Search from "@components/Search";
import EditNote from "@components/EditNote";
import DateAndUser from "@components/DateAndUser";
import TextAndLinks from "@components/TextAndLinks";

import ReactModal from "react-modal";
import ActiveSearchFilters from "@components/ActiveSearchFilters";
import { paymentOptions } from "../../Content/Courses/Course/Details/Main/data";
const examOptions = [
  { _id: "passed", name: "Pass" },
  { _id: "failed", name: "Failed" },
  { _id: "videos yet to be competed", name: "Videos yet to be completed" },
  { _id: "not appeared", name: "Not Appeared" },
  { _id: "no exam", name: "No Exam" },
  { _id: "unlock", name: "Unlocked" },
];

function UsersView(props) {
  return (
    <div
      ref={props.fullscreenElementRef}
      className="rightHandRightPageMainContainer userMainPage"
      style={{
        maxWidth: "100%",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh-58px)",
      }}
    >
      <Search
        value={props.filters.searchText}
        startDate={props.filters.startDate}
        endDate={props.filters.endDate}
        yearTag={props.filters.yearTag}
        monthTag={props.filters.monthTag}
        mainTag={props.filters.mainTag}
        tagStatus={props.filters.tagStatus}
        subTag={props.filters.subTag}
        manualTag={props.filters.manualTag}
        placeholder={"Search users"}
        isFilterEnabled={true}
        filters={[
          {
            placeholder: "Category",
            value:
              props.filters.categories.length > 0
                ? `(${props.filters.categories.length}) selected`
                : "",
            icon: DownIcon,
          },
          {
            placeholder: "Select Course",
            value:
              props.filters.courseIds.length > 0
                ? `(${props.filters.courseIds.length}) selected`
                : "",
            icon: DownIcon,
          },
          {
            placeholder: "Exam Status",
            value: props.filters.examStatus,
            icon: DownIcon,
          },
        ]}
        handleFilter={props.handleFilterPopUp}
        isTagFilterEnabled={true}
        onSearch={(data) => props.handleSearch(data)}
      >
        <div>No Filters Added</div>
      </Search>

      <div className="marginLeftContainer d-flex align-items-center mb-2 gap-4">
        <h4 className="heading mb-0">Users</h4>
        <ActiveSearchFilters
          filters={[
            { icon: "search", value: props.finalFilters.searchText },
            {
              icon: "calender",
              value:
                props.finalFilters.startDate || props.finalFilters.endDate
                  ? `${props.finalFilters.startDate} - ${props.finalFilters.endDate}`
                  : "",
            },
            {
              icon: "",
              value: props.finalFilters.categories.length
                ? `Category: (${props.finalFilters.categories.length})`
                : "",
            },
            {
              icon: "",
              value: props.finalFilters.courseIds.length
                ? `Course: (${props.finalFilters.courseIds.length})`
                : "",
            },
            {
              icon: "",
              value: props.finalFilters.examStatus
                ? `Exam: ${props.finalFilters.examStatus}`
                : "",
            },
            {
              icon: "",
              value: props.tagCount ? `Tags: (${props.tagCount})` : "",
            },
          ]}
          handleClick={props.handleClearFilter}
        />
      </div>

      <DynamicTabs
        allTabs={props.folders}
        activeTab={props.activeTab}
        onTabClick={props.handleTabClick}
      />

      <div className="d-flex align-items-center w-100 justify-content-between">
        <ButtonBars
          buttons={[
            {
              type: "round",
              text: "+",
              onClick: (event) => {
                props.handlePopUpOpen(event, "createUser", null);
              },
            },
            {
              type: "simple",
              text: "Add Course",
              onClick: (event) => {
                props.handlePopUpOpen(event, "addCourse", null);
              },
              disabled: props.disableBulkActionBtn,
            },
            {
              type: "simple",
              text: "Add Series",
              onClick: (event) => {
                props.handlePopUpOpen(event, "addSeries", null);
              },
              disabled: props.disableBulkActionBtn,
            },
            {
              type: "simple",
              text: "Add Test",
              onClick: (event) => {
                props.handlePopUpOpen(event, "addTest", null);
              },
              disabled: props.disableBulkActionBtn,
            },
            {
              type: "dropdownOnClick",
              text: "Series Status",
              onClick: (event) => {
                props.handlePopUpOpen(event, "seriesStatus", null);
              },
              disabled: props.disableBulkActionBtn,
            },
            {
              type: "dropdownOnClick",
              text: "Exam Status",
              onClick: (event) => {
                props.handlePopUpOpen(event, "examStatus", null);
              },
              disabled: props.disableBulkActionBtn,
            },
            {
              type: "dropdownOnClick",
              text: "Move to",
              onClick: (event) => {
                props.handlePopUpOpen(event, "moveToFolder", null);
              },
              disabled: props.disableBulkActionBtn,
            },
          ]}
        />
        <div className="d-flex gap-2 controlsRight">
          <button onClick={props.toggleFullscreen}>
            <img src={ExpandIcon}></img>
            <br></br>
            Expand
          </button>
          <button
            onClick={(event) => props.handlePopUpOpen(event, "settings", null)}
          >
            <img src={SettingIcon}></img>
            <br></br>
            Settings
          </button>
          <button
            onClick={(e) => props.handlePopUpOpen(e, "archiveRequest", null)}
          >
            <img src={FolderIcon}></img>
            <br></br>
            Archive
          </button>
          <button onClick={props.handleColumnBtnClick}>
            <img src={ColumnsIcon}></img>
            <br></br>
            Columns
          </button>
        </div>
      </div>

      {/* Start the table grid */}
      <BasicTableGrid
        gridId="userData" // this must be unique for each grid
        data={props.deferredData} // Data to be displayed
        initialSize={[
          35, 198, 150, 150, 221, 227, 150, 150, 241, 150, 150, 150, 150, 150,
        ]}
        initialSorting={[{ id: "Purchase Date", desc: false }]}
        fixedCols={2} // How many columns are fixed and not scrolling
        handleSelect={(selectedRows) => {
          props.handleCheckBoxes(selectedRows);
        }}
        length={20}
        // handleSelect = {()=>{}}

        pagination={props.pagination}
        setPagination={props.setPagination}
        onSortChange={(data) => props.handleSort(data)}
        columnsOptionPopUpRef={props.columnsOptionPopUpRef}
        // Columns to be extracted from data
        columns={[
          {
            header: "Name",
            cell: (data) => {
              var info = data.row.original;

              return (
                <div className="d-flex gap-2 align-items-center">
                  {props.isProfilePictureVisible ? (
                    <div
                      className="thumbnailContainer"
                      style={{
                        backgroundImage: `url(${info.displayPhoto})`,
                        height: "30px",
                        width: "30px",
                        borderRadius: "15px",
                        border: "0px solid black",
                      }}
                    ></div>
                  ) : null}
                  <TextAndLinks
                    // title= {info?.userDetails?.fullName}
                    title={
                      info?.firstName || info?.middleName || info?.lastName
                        ? `${info?.firstName ?? ""} ${info?.middleName ?? ""} ${
                            info?.lastName ?? ""
                          }`
                        : info.fullName
                        ? info.fullName
                        : info.username
                        ? info.username
                        : info.email
                    }
                    subTitle={"Details"}
                    images={[EditPencilIcon]}
                    onClick={(event, index) => {
                      props.handleRedirectToProfilePage(
                        event,
                        info._id,
                        "UserProfile"
                      );
                    }}
                  />
                </div>
              );
            },
          },
          {
            header: "Phone Number",
            cell: (data) => {
              var info = data.row.original;
              return (
                <PhoneNumber
                  userId={info?._id}
                  countryCode={info.countryCode}
                  number={info.contactNumber}
                  type="phone"
                  onChange={() => {}}
                />
              );
            },
          },
          {
            header: "Course",
            cell: (data) => {
              return data.row.original.courseMnemonic;
            },
          },
          {
            header: "WhatsApp Number",
            cell: (data) => {
              var info = data.row.original;
              return (
                <PhoneNumber
                  userId={info?._id}
                  countryCode={info.whatsAppCountryCode}
                  number={info.whatsAppNumber}
                  type="whatsApp"
                  onChange={() => {}}
                />
              );
            },
          },
          {
            header: "Purchase Date",
            accessorFn: (row) => {},
            cell: (data) => {
              const info = data.row.original;

              return info.createdAt ? (
                <DateAndUser date={info.purchaseAt} image={""} imageText={""} />
              ) : (
                "No Purchase Found"
              );
            },
          },
          {
            header: "Tags",
            cell: (data) => {
              const info = data.row.original;
              const tags = data.row.original?.courseTags;
              const userManualTags = data.row.original.userManualTags;
              if (tags) {
               
                return (
                  <Tags
                    userId={info?._id}
                    automaticTags={tags} // [{key:value}]
                    manualTags={userManualTags}
                    onChange={() => {}}
                  />
                );
              }
            },
          },
          {
            header: "Notes",
            cell: (data) => {
              return (
                <EditNote
                  value={data.row.original.notes}
                  onChange={() => {}}
                  userId={data.row.original._id}
                />
              );
            },
          },
          {
            header: "Acc. Created",
            accessorFn: (row) => {},
            cell: (data) => {
              const info = data.row.original;
              return (
                <DateAndUser date={info?.createdAt} image={""} imageText={""} />
              );
            },
          },
          {
            header: "Exam Status",
            cell: (data) => {
              const userData = data.row.original;
              return (
                <div className="d-flex justify-content-between w-100">
                  <span>
                    {userData.result === "passed"
                      ? "Passed"
                      : userData.result
                      ? userData.result
                      : "No result"}
                  </span>
                  {userData.result === "passed" ? (
                    <img
                      src={EyeIcon}
                      className="eyeIcon"
                      alt="Eye Icon"
                      onClick={() =>
                        props.onResultEyeClick(
                          userData.purchaseId,
                          userData.resultId
                        )
                      }
                    />
                  ) : null}
                </div>
              );
            },
          },
          { header: "Current Rem. Balance", accessorKey: "remBalance" },
          {
            header: "Email",
            cell: (data) => {
              return data.row.original.email;
            },
          },
          {
            header: "Series",
            cell: (data) => {
              const info = data.row.original;
              return info?.series?.map((series) => series.mnemonic + ", ");
            },
          },
          // {   header: 'Result', accessorKey: 'result' },
          { header: "Fee Paid", accessorKey: "fee" },
          {
            header: "Payment Gateway",
            cell: (data) => {
              return data.row.original.paymentMethod === 'free'? "Free":
              paymentOptions.filter(gateway=> gateway.paymentGateway === data.row.original.paymentMethod)[0]?.text;
            },
          },
          {
            header: "All Courses",
            cell: (data) => {
              return data.row.original.allCourses;
            },
          },
        ]}
      />
      {/* End the table grids  */}

      <ReactModal
        isOpen={props.isResultModalOpen}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "70px",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: 100,
          overlay: { zIndex: 1000, backgroundColor: "rgba(0, 0, 0, 0.7)" },
        }}
        onRequestClose={props.onCloseModal}
        ref={props.resultModalRef}
      >
        <div>
          <button onClick={props.onCloseModal} style={{ float: "right", border: "none", background: "none", fontSize: "30px"}}>
            X
          </button>
          <ExamResult
            purchaseId={props.purchaseId}
            resultId={props.resultId}
          ></ExamResult>
        </div>
      </ReactModal>

      {/* All Pop Ups */}
      {props.popUp === "createUser" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <div handleClose={props.handlePopUpClose} className="createUserDiv">
            <div
              onClick={(event) => {
                props.handleRedirectToProfilePage(event, "none", "none");
              }}
            >
              <img src={PlusIcon} style={{ height: "15px" }}></img>
              <span>Create User</span>
            </div>
            <div
              onClick={() => {
                props.userExcelFileInputRef.current.click();
              }}
            >
              <img src={ImportIcon} style={{ height: "15px" }}></img>
              <span>Import User(s) from Excel</span>
              <input
                type="file"
                accept=".xlsx, .xls"
                ref={props.userExcelFileInputRef}
                onChange={props.handleUserExcelInput}
                hidden
              ></input>
            </div>
          </div>
        </PopUp>
      )}
      {props.popUp === "addCourse" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
          isFormalRequest={props.roleCode === 2}
        >
          <AddCoursePopUp
            userIds={props.selectedUserIds}
            handleClose={props.handlePopUpClose}
            handleSave={props.handlePopUpClose}
            isFormalRequest={props.roleCode == 2}
          />
        </PopUp>
      )}
      {props.popUp === "addSeries" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <AddSeriesPopUp
            purchaseIds={props.listOfCheckedUsers.map(
              (user) => user.purchaseId
            )}
            handleClose={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
          />
        </PopUp>
      )}
      {props.popUp === "addTest" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <AddTestPopUp
            userIds={props.selectedUserIds}
            handleClose={props.handlePopUpClose}
            handleSave={props.handlePopUpClose}
          />
        </PopUp>
      )}
      {props.popUp === "seriesStatus" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <SeriesStatusPopUp
            handleCancel={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
            purchaseIds={props.listOfPurchaseIds}
          />
        </PopUp>
      )}
      {props.popUp === "examStatus" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <ExamStatusPopUp
            handleCancel={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
            purchaseIds={props.listOfPurchaseIds}
          />
        </PopUp>
      )}
      {props.popUp === "moveToFolder" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <MoveToPopUp
            folders={props.folders.slice(1)}
            handleCancel={props.handlePopUpClose}
            handleSave={(event, data) => props.handlePopUpSave(data, event)}
          />
        </PopUp>
      )}
      {props.popUp === "settings" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left - 50}
          handleOutSideClick={props.handlePopUpClose}
        >
          <div style={{ height: "200px", overflow: "auto" }}>
            {props.settingOptions.map((option, index) => (
              <div
                key={index}
                onClick={(e) => props.handleSettingOption(e, option.id)}
                className="settingOption d-flex"
              >
                {option.text}
                <img
                  className="ms-auto"
                  src={option.img}
                  style={{ height: "12px" }}
                />
              </div>
            ))}
          </div>
        </PopUp>
      )}
      {props.popUp === "editNumber" && (
        <PopUp
          visible={props.isPopUpVisible && props.popUp === ""}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <EditNumberPopUp
            userId={props.selectedNumber?.userId}
            currentCountryCode={props.selectedNumber?.countryCode}
            currentPhoneNumber={props.selectedNumber?.number}
            numberType={props.selectedNumber?.type}
            handleClose={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
          />
        </PopUp>
      )}
      {props.popUp === "addRule" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top - 100}
          left={props.popUpPosition.left - 300}
          handleOutSideClick={props.handlePopUpClose}
          isFormalRequest={props.roleCode === 2}
        >
          <AddRulePopUp
            handleClose={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
            userIds={props.listOfUserIds}
            isFormalRequest={props.roleCode == 2}
          />
        </PopUp>
      )}
      {props.popUp === "changeTestStatus" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top - 130}
          left={props.popUpPosition.left - 200}
          handleOutSideClick={props.handlePopUpClose}
        >
          <TestStatusPopUp
            handleCancel={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
            userIds={props.listOfUserIds}
          />
        </PopUp>
      )}
      {props.popUp === "enableCourseOn" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top - 120}
          left={props.popUpPosition.left - 300}
          handleOutSideClick={props.handlePopUpClose}
          isFormalRequest={props.roleCode === 2}
        >
          <EnableCoursePopUp
            handlePopUpClose={props.handlePopUpClose}
            handlePopUpSave={props.handlePopUpSave}
            isFormalRequest={props.roleCode == 2}
          />
        </PopUp>
      )}
      {props.popUp === "setCourseEndDate" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <div>setCourseEndDate popup</div>
        </PopUp>
      )}
      {props.popUp === "dashBoardFormCompulsion" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top - 150}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <SelectionPopUp
            data={[
              { _id: "basicDetails", name: "Basic Details" },
              { _id: "legalInformation", name: "Legal Information" },
              { _id: "deliveryAddress", name: "Delivery Address" },
              { _id: "identityVerification", name: "Identity Verification" },
              { _id: "bssDetails", name: "BSS Details" },
            ]}
            optionKey={"name"}
            title={""}
            searchPlaceholder={""}
            isSearchEnabled={false}
            height={"auto"}
            width={"auto"}
            selectedItems={[]}
            dataKey={"_id"}
            selectedItemsKey={"_id"}
            selectionType={"multiple"}
            handleCancel={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
          />
        </PopUp>
      )}
      {(props.popUp === "examStatusResult" ||
        props.popUp === "testStatusResult") && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top - 100}
          left={props.popUpPosition.left - 300}
          handleOutSideClick={props.handlePopUpClose}
        >
          <ResultStatusPopUp
            handleCancel={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
            isTest={props.popUp === "testStatusResult"}
          />
        </PopUp>
      )}

      {props.popUp === "archiveRequest" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left - 400}
          handleOutSideClick={props.handlePopUpClose}
          isFormalRequest={props.roleCode === 2}
        >
          <ConfirmArchivePopUp
            handlePopUpClose={props.handlePopUpClose}
            handlePopUpSave={props.handlePopUpSave}
            isFormalRequest={props.roleCode == 2}
          />
        </PopUp>
      )}
      {/* 
    // deleteCourse => replaceCourse => enableCourse => disableAccount => moveToArchive => createBSSForm */}

      {props.popUp === "disableAccount" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top - 100}
          left={props.popUpPosition.left - 210}
          handleOutSideClick={props.handlePopUpClose}
          isFormalRequest={props.roleCode === 2}
        >
          <DisableAccountPopUp
            handlePopUpClose={props.handlePopUpClose}
            handlePopUpSave={props.handlePopUpSave}
            isFormalRequest={props.roleCode == 2}
            selectedCount={props.listOfCheckedUsers?.length}
          />
        </PopUp>
      )}
      {props.popUp === "replaceCourse" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top - 130}
          left={props.popUpPosition.left - 200}
          handleOutSideClick={props.handlePopUpClose}
          isFormalRequest={props.roleCode === 2}
        >
          <ReplaceCoursePopUp
            handlePopUpClose={props.handlePopUpClose}
            handlePopUpSave={props.handlePopUpSave}
            isFormalRequest={props.roleCode == 2}
            selectedCount={props.listOfCheckedUsers?.length}
          />
        </PopUp>
      )}
      {props.popUp === "deleteCourse" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top - 100}
          left={props.popUpPosition.left - 200}
          handleOutSideClick={props.handlePopUpClose}
          isFormalRequest={props.roleCode === 2}
          selectedCount={props.listOfCheckedUsers?.length}
        >
          <DeleteCoursePopUp
            handlePopUpClose={props.handlePopUpClose}
            handlePopUpSave={props.handlePopUpSave}
            isFormalRequest={props.roleCode == 2}
            selectedCount={props.listOfCheckedUsers?.length}
          />
        </PopUp>
      )}
      {props.popUp === "createBSS" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top - 150}
          left={props.popUpPosition.left - 250}
          handleOutSideClick={props.handlePopUpClose}
          isFormalRequest={props.roleCode === 2}
        >
          <CreateBssFormsPopUp
            handlePopUpClose={props.handlePopUpClose}
            handlePopUpSave={props.handlePopUpSave}
            isFormalRequest={props.roleCode == 2}
          />
        </PopUp>
      )}
      {props.popUp === "removeUser" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top - 150}
          left={props.popUpPosition.left - 250}
          handleOutSideClick={props.handlePopUpClose}
          isFormalRequest={props.roleCode === 2}
        >
          <RemoveUserPopUp
            handlePopUpClose={props.handlePopUpClose}
            handlePopUpSave={props.handlePopUpSave}
            isFormalRequest={props.roleCode === 2}
          />
        </PopUp>
      )}

      {/* Search Filter PopUp */}
      <div style={{ zIndex: "9999999999999999999999" }}>
        {/* {props.popUp === "filterTag"  &&<PopUp visible={props.isPopUpVisible}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<div>Tag filter</div>
			</PopUp>} */}
        {props.popUp === "filterCategory" && (
          <PopUp
            visible={props.isPopUpVisible}
            top={props.popUpPosition.top}
            left={props.popUpPosition.left}
            handleOutSideClick={props.handlePopUpClose}
          >
            <SelectionPopUp
              data={props.allCategories}
              optionKey={"title"}
              title={"Select Category"}
              searchPlaceholder={"Search category"}
              isSearchEnabled={true}
              height={"300px"}
              width={"400px"}
              selectedItems={props.allCategories.filter((category) =>
                props.filters.categories.includes(category._id)
              )}
              dataKey={"_id"}
              selectedItemsKey={"_id"}
              selectionType={"multiple"}
              handleCancel={props.handlePopUpClose}
              handleSave={props.handlePopUpSave}
            />
          </PopUp>
        )}
        {props.popUp === "filterCourse" && (
          <PopUp
            visible={props.isPopUpVisible}
            top={props.popUpPosition.top}
            left={props.popUpPosition.left}
            handleOutSideClick={props.handlePopUpClose}
          >
            <SelectionPopUp
              data={props.allCourses}
              optionKey={"title"}
              title={"Select Course"}
              searchPlaceholder={"Search course"}
              isSearchEnabled={true}
              height={"300px"}
              width={"400px"}
              selectedItems={props.allCourses.filter((course) =>
                props.filters.courseIds.includes(course._id)
              )}
              dataKey={"_id"}
              selectedItemsKey={"_id"}
              selectionType={"multiple"}
              handleCancel={props.handlePopUpClose}
              handleSave={props.handlePopUpSave}
            />
          </PopUp>
        )}
        {props.popUp === "filterExamStatus" && (
          <PopUp
            visible={props.isPopUpVisible}
            top={props.popUpPosition.top}
            left={props.popUpPosition.left}
            handleOutSideClick={props.handlePopUpClose}
          >
            <SelectionPopUp
              data={examOptions}
              optionKey={"name"}
              title={""}
              searchPlaceholder={""}
              isSearchEnabled={false}
              height={"auto"}
              width={"auto"}
              selectedItems={examOptions.filter(
                (item) => props.filters.examStatus === item._id
              )}
              dataKey={"_id"}
              selectedItemsKey={"_id"}
              selectionType={"single"}
              handleCancel={props.handlePopUpClose}
              handleSave={props.handlePopUpSave}
            />
          </PopUp>
        )}
      </div>
    </div>
  );
}

export default UsersView;
