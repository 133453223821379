import React, { useState, useEffect, useRef } from "react";
import CourseCategoryView from "./view";

import {
  useNavigate,
  defer,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as CategoryServices from "@services/Category";
import * as CategoryActions from "@redux/actions/Category";

import { navigationData } from "./data";
import { decryptText } from "@library/enc-dec";

import { toggleFullscreen } from "@helpers/common.js";
import { produce } from "immer";

import Swal from "sweetalert2";
var listOfCheckedCategories = [];

function CourseCategory(props) {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  const fullscreenElementRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [deferredData, setDeferredData] = useState({});
  const [allCourseCategories, setAllCourseCategories] = useState("");

  const [isSorted, setIsSorted] = useState(props.filterInformation.isSorted);
  const [sortBy, setSortBy] = useState(props.filterInformation.sortBy);

  // START : UseState's to control the functionality of setting visibility of Course
  const [internalIsPublic, setInternalIsPublic] = useState(false);
  const [internalIsUnlisted, setInternalIsUnlisted] = useState(false);
  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);
  const [disableSaveBtnOfVisibility, setDisableSaveBtnOfVisibility] =
    useState(false);
  const visibilityPopupContainerRef = useRef(null);
  const [
    is_VisibilityPopupContainerRef_Visible,
    set_Is_VisibilityPopupContainerRef_Visible,
  ] = useState(false);
  const [
    visibilityPopupContainerRef_Position,
    set_VisibilityPopupContainerRef_Position,
  ] = useState({ top: 0, left: 0 });
  const [currentSelectedCategory, setCurrentSelectedCategory] = useState(false);

  // for pagination
  const [pageNo, setPageNo] = useState(props.pageNo);

  const [searchParams, setSearchParams] = useSearchParams();
  const controller = useRef(new AbortController())
  const [pageLoaded, setPageLoaded] = useState(false);
  
  useEffect(()=>{
    if(props.isSelected)
    {
      setPageLoaded(true)
      setSearchParams({
        page: props.filterInformation.page,
        limit: props.filterInformation.limit,
      });
    }
    return ()=>{
      controller.current.abort()
    }
  },[props.isSelected])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!Number(queryParams.get("limit")) && Number(queryParams.get("page"))) {
      return;
    }
    if(props.isSelected && Number(queryParams.get('limit')>0) &&
      !(Number(queryParams.get("limit")) == props.filterInformation.limit 
       &&
       Number(queryParams.get("page")) == props.filterInformation.page 
      )
    ){
    props.updateFilterInformation({
      page: Number(queryParams.get("page")),
      limit: Number(queryParams.get("limit")),
      sortBy,
      isSorted,
      searchText: props.filterInformation.searchText,
    });}
  }, [
    Number(queryParams.get("limit")),
    Number(queryParams.get("page")),
    sortBy,
    isSorted,
  ]);

  useEffect(() => {
    if (props.filterInformation.limit > 0 && props.isSelected) {
      getAllCategories();
    }
  }, [props.filterInformation, pageLoaded]);

  const getAllCategories = async () => {
    try {
      controller.current.abort();
      controller.current = new AbortController();
      let signal = controller.current.signal;
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: props.filterInformation.page,
        limit: props.filterInformation.limit,
        sortBy: props.filterInformation.sortBy,
        isSorted: props.filterInformation.isSorted,
        searchText: props.filterInformation.searchText,
      };
      var deferredResponse = defer({
        res: CategoryServices.getAllCategories(payload, authToken, signal),
      });
      setDeferredData(deferredResponse);

      deferredResponse.data.res
        .then((response) => {
          setAllCourseCategories(response);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (err) {
      console.log("Error coming while getting all categories", err);
    }
  };

  //Fn to check and uncheck all checkboxes
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedCategories = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };

  // To sort data as per date
  const handleSort = (data) => {
    const mapping = { "Date Added": "createdAt", "Date Modified": "updatedAt" };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    setSortBy(id);
    setIsSorted(data[0]?.desc ? data[0].desc : false);
  };

  //Fn to handle hiding of popup's when clicked outside the respective container
  const handleOutsideClick = (event) => {
    if (
      visibilityPopupContainerRef.current &&
      !visibilityPopupContainerRef.current.contains(event.target)
    ) {
      set_Is_VisibilityPopupContainerRef_Visible(false);
    }
  };

  //START : Logic for the functionality of updating visibility of course

  //Fn to handle when visibility btn is click which will update the val of radio btns as per the selected course
  const handleVisibilityBtnClick = (event, categoryId, isPublic) => {
    set_Is_VisibilityPopupContainerRef_Visible(true);

    let currentEle = event.target;
    let left = currentEle.getBoundingClientRect().left;
    let top = window.scrollY + currentEle.getBoundingClientRect().bottom;
    set_VisibilityPopupContainerRef_Position({ left: left, top: top });

    setInternalIsPublic(false);
    setInternalIsUnlisted(false);
    setDisableSaveBtnOfVisibility(false);

    if (categoryId === "bluk") {
      setDisableSaveBtnOfVisibility(true);
      setCurrentSelectedCategory("bulk");
    } else {
      setCurrentSelectedCategory(categoryId);

      if (isPublic) {
        setInternalIsPublic(true);
        setInternalIsUnlisted(false);
      } else {
        setInternalIsPublic(false);
        setInternalIsUnlisted(true);
      }
    }
  };

  //Fn to close the visibility popup container
  const handleVisibilityCancleBtnClick = (event) => {
    set_Is_VisibilityPopupContainerRef_Visible(false);
  };

  //Fn to handle radio btn of public and unlisted
  const handleVisibilityRadioBtns = (event, value) => {
    if (value === "public") {
      setInternalIsPublic(true);
      setInternalIsUnlisted(false);
    } else {
      setInternalIsPublic(false);
      setInternalIsUnlisted(true);
    }
    setDisableSaveBtnOfVisibility(false);
  };

  //Fn to update visibility for the courses
  const handleSaveBtnVisibility = (event) => {
    if (currentSelectedCategory === "bulk") {
      handleCategoryVisiblity(
        internalIsPublic,
        listOfCheckedCategories.map((item) => item._id)
      );
    } else {
      handleCategoryVisiblity(internalIsPublic, [currentSelectedCategory]);
    }

    set_Is_VisibilityPopupContainerRef_Visible(false);
    // listOfCheckedCategories = [];
  };

  const handleCategoryVisiblity = async (isPublic, categoryIds) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = { categoryDocIds: categoryIds, isPublic: isPublic };
      let response = await CategoryServices.updateCategoryVisibility(
        payload,
        authToken
      );

      if (response.success) {
        let updatedAllCourseCategories = produce(
          allCourseCategories,
          (draft) => {
            draft.data = draft.data.map((category) => {
              if (categoryIds.includes(category._id)) {
                category.isPublic = isPublic;
              }
              return category;
            });
          }
        );
        setAllCourseCategories(updatedAllCourseCategories);
        var deferredResponse = defer({ res: updatedAllCourseCategories });
        setDeferredData(deferredResponse);
        // getAllCategories()
        return;
      }
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (err) {
      console.log("Error coming while handling category visiblity", err);
    }
  };

  const handleDeleteBulk = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        categoryDocIds: listOfCheckedCategories.map((item) => item._id),
      };
      let response = await CategoryServices.deleteCategories(
        payload,
        authToken
      );
      if (response.success) {
        getAllCategories();
        // listOfCheckedCategories = [];
        document.getElementById("bulkCheckbox").checked = false;
        setDisableBulkActionBtn(true);
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (err) {
      console.log("Error coming while deleting the categories", err);
    }
  };

  //Fn to redirct to course category home page
  const handleRedirectToHome = (event, categoryId) => {
    if (categoryId === "none") {
      navigate("/course-category", { state: { navigationData } });
    } else {
      navigate("/course-category/" + categoryId, { state: { navigationData } });
    }
  };

  const handleSearch = (data) => {
    let updatedFilters = produce(props.filterInformation, (draft) => {
      draft.page = 0;
      draft.searchText = data.text;
    });
    props.updateFilterInformation(updatedFilters);
  };

  return (
    <>
      <CourseCategoryView
        deferredData={deferredData}
        handleRedirectToHome={handleRedirectToHome}
        handleSort={handleSort}
        isSorted={isSorted}
        sortBy={sortBy}
        searchText={props.filterInformation.searchText}
        handleSearch={handleSearch}
        handleOutsideClick={handleOutsideClick}
        //START : UseStates & fn to control the functionality of upating visibility of course
        visibilityPopupContainerRef={visibilityPopupContainerRef}
        is_VisibilityPopupContainerRef_Visible={
          is_VisibilityPopupContainerRef_Visible
        }
        visibilityPopupContainerRef_Position={
          visibilityPopupContainerRef_Position
        }
        internalIsPublic={internalIsPublic}
        internalIsUnlisted={internalIsUnlisted}
        handleVisibilityBtnClick={handleVisibilityBtnClick}
        handleSaveBtnVisibility={handleSaveBtnVisibility}
        handleVisibilityCancleBtnClick={handleVisibilityCancleBtnClick}
        handleVisibilityRadioBtns={handleVisibilityRadioBtns}
        //END : UseStates & fn to control the functionality of upating visibility of course

        //START : UseStates & fn for the functionality of bulk actions
        disableSaveBtnOfVisibility={disableSaveBtnOfVisibility}
        disableBulkActionBtn={disableBulkActionBtn}
        handleDeleteBulk={handleDeleteBulk}
        //END : UseStates & fn for the functionality of bulk actions

        //START : UseStates & fn to control the functionality of checkboxes
        handleCheckBoxes={handleCheckBoxes}
        //END : UseStates & fn to control the functionality of checkboxes

        // for pagination
        pageNo={pageNo}
        // Fullscreen
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    allCategories: state.category.allCategories,
    totalNumberOfCategories: state.category.totalNumberOfCategories,
    categorySet: state.category.categorySet,
    pageNo: state.category.pageNo,
    filterInformation: state.category.filterInformation,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAllCategories: CategoryActions.updateAllCategories,
      updateTotalNumOfCategories: CategoryActions.updateTotalNumOfCategories,
      updateCategorySet: CategoryActions.updateCategorySet,
      updatePageNo: CategoryActions.updatePageNo,
      updateFilterInformation: CategoryActions.updateFilterInformation,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCategory);
