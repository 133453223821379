import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, defer, useLocation} from 'react-router-dom';

import moment from 'moment';

import {colors} from '@assets/colors.js'

import { decryptText } from '@library/enc-dec';
import { produce } from 'immer';
import Swal from 'sweetalert2';
import {getEventElementPosition, toggleFullscreen} from '@helpers/common.js';
import LeadsView from './view';
import * as AnalysisServices from '@services/Analysis';

const mainDateOption=['Today','Yesterday','This Month','This Week', 'This Year', 'Last 7 days','Last 30 days','Last 180 days','Last 360 days', 'This financial year','All time','Select']
const years = ['2022','2023','2024']
const months= ['January','February','March','April','May','June','July','August','September','October','November','December']


var listOfCheckedRows = [];
const currentDate = new Date();

const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

const startDateStr = startDate.toISOString().split('T')[0];
const endDateStr = endDate.toISOString().split('T')[0];

function Geography(props){   
	const fullscreenElementRef = useRef(null);
	const columnsOptionPopUpRef = useRef(null);
	const [selectedYear, setSelectedYear] = useState(null);
	
    const [startDate, setStartDate] = useState(startDateStr)
    const [endDate, setEndDate] = useState(endDateStr)
	const controller = useRef(new AbortController())

    useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				columnsOptionPopUpRef.current &&
				!columnsOptionPopUpRef.current.contains(event.target)
			) {
				columnsOptionPopUpRef.current.style.display = 'none';
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
			controller.current.abort();
		};
	}, []);

	const [deferredData, setDeferredData] = useState({});
	const [isSorted, setIsSorted] = useState(false);
	const [sortBy, setSortBy] = useState('updatedAt');
    const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const limit = Number(queryParams.get('limit'));
	const page = Number(queryParams.get('page'));
	const [popUp, setPopUp] =useState('');
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	const [popUpPosition, setPopUpPosition] = useState({});

	const [pageData, setPageData] = useState({});
	const [isLoading, setIsLoading ] = useState(true);
	const [chartOptions, setChartOptions] =useState({
        // Data: Data to be displayed in the chart
        data: [
            { date: 'Jan', Total: 209, India: 209, USA: 90},
			{ date: 'Feb', Total: 209, India: 209, USA: 90},
			{ date: 'Mar', Total: 4603, India: 4603, USA: 90},
			{ date: 'Apr', Total: 1209, India: 1209, USA: 90},
			{ date: 'May', Total: 8162, India: 8162, USA: 90},
			{ date: 'Jun', Total: 3209, India: 3209, USA: 90},
			{ date: 'Jul', Total: 1228, India: 1228, USA: 90},
			{ date: 'Sep', Total: 10145, India: 10145, USA: 90},
			{ date: 'Nov', Total: 2809, India: 2809, USA: 90},

		],

        // Series: Defines which chart type and data to use
        series: [{ type: 'line', xKey: 'date', yKey: 'Total', yName: "Total" },
			// { type: 'line', xKey: 'date', yKey: 'India', yName: "India" },
			// { type: 'line', xKey: 'date', yKey: 'USA', yName: "USA" },
		],
		connectMissingData:true,
        axis:[
            {type: 'number', position:'bottom'},
            { type: 'log', position: 'left', nice: false, base:10, tick: {
				minSpacing: 200,
			  },},
    ],
	animation: {
		enabled: true,
		duration:300
	},
	legend:{
		enabled:true,
		position:'top'
	},
	// fill: item.color
      })

	  const [chartOptions2, setChartOptions2] =useState({
        // Data: Data to be displayed in the chart
        data: [
            { date: 'Jan', Total: 209, India: 209, USA: 90},
			{ date: 'Feb', Total: 209, India: 209, USA: 90},
			{ date: 'Mar', Total: 4603, India: 4603, USA: 90},
			{ date: 'Apr', Total: 1209, India: 1209, USA: 90},
			{ date: 'May', Total: 8162, India: 8162, USA: 90},
			{ date: 'Jun', Total: 3209, India: 3209, USA: 90},
			{ date: 'Jul', Total: 1228, India: 1228, USA: 90},
			{ date: 'Sep', Total: 10145, India: 10145, USA: 90},
			{ date: 'Nov', Total: 2809, India: 2809, USA: 90},

		],

        // Series: Defines which chart type and data to use
        series: [{ type: 'bar', xKey: 'date', yKey: 'Total', yName: "Total", stacked: true },
			// { type: 'bar', xKey: 'date', yKey: 'India', yName: "India", stacked: true  },
			// { type: 'bar', xKey: 'date', yKey: 'USA', yName: "USA", stacked: true  },
		],
		connectMissingData:true,
        axis:[
            {type: 'number', position:'bottom'},
            { type: 'log', position: 'left', nice: false, base:10, tick: {
				minSpacing: 200,
			  },},
    ],
	animation: {
		enabled: true,
		duration:300
	},
	legend:{
		enabled:true,
		position:'top'
	},
	// fill: item.color
      })

	const [chartType, setChartType] = useState("Line");

	const [pageLoaded, setPageLoaded] = useState(false);

	useEffect(()=>{
		if(props.isSelected){
			setPageLoaded(true)
		}
		return ()=>{
			controller.current.abort();
			setPageLoaded(false);
		}
	},[props.isSelected])

	useEffect(()=>{
		if(pageLoaded){
         getGeographyData()
		}
    },[startDate, endDate, pageLoaded])

	const getGeographyData=async()=>{
		setIsLoading(true)
		controller.current.abort();
		controller.current = new AbortController();
		const signal = controller.current.signal;
        let authToken = decryptText(localStorage.getItem('aEmediat'));
		let payload = {
                            startDate:startDate,
                            endDate : endDate
                        };

		try
        {
			var deferredResponse = defer({ res: AnalysisServices.getLeadCountryReport(payload, authToken, signal)
			.then(preprocessData).catch(error=>{
				console.log("Error in fetching analysis data")
				setPageLoaded(false)
				return {}
			}) });
        
			setDeferredData(deferredResponse)

			deferredResponse.data.res.then(response => {
				setPageData(response)
				calculateChartDate(response.graph)
				setIsLoading(false)
			})
			.catch(error => {
				Swal.fire({icon: "error",title: error.message,showConfirmButton: false,timer: 2500});
				setIsLoading(false)
				setPageLoaded(false)
			});
		}catch(error){

		}                                   
    }

	
	const preprocessData = (response) => {
		if(!response.status===200)
			{ return null}
		response.pageLimit = 1000
		response.currentDocCount=response.data.overAllData.length
		response.totalDocCount=response.data.overAllData.length
		response.graph = response.data.graph
		response.data = response.data.overAllData
		const cumulativeData = response.data.reduce((acc, countryData) => {
			acc.pageViews += countryData.pageViews || 0;
			acc.uniquePageViews += countryData.uniquePageViews || 0;
			acc.leadSignUps += countryData.leadSignUps || 0;
			acc.leadConversions += countryData.leadConversions || 0;
			acc.revenueFromLead += countryData.revenueFromLead || 0;
		
			// Handle leadConversionRate separately (use rate from countries with valid data)
			if (countryData.leadConversionRate !== null) {
				acc.leadConversionRate = countryData.leadConversionRate;
			}
		
			return acc;
		}, {
			country: "Total",
			pageViews: 0,
			uniquePageViews: 0,
			leadSignUps: 0,
			leadConversions: 0,
			leadConversionRate: null,
			revenueFromLead: 0
		});
		response.data.unshift(cumulativeData)
		response.data = response.data?.map((item,index)=>{
			item.color = colors[index%colors.length]
			return item
		})
		return response
	}

	function calculateChartDate(data){
				
				
		// List of all countries involved (add more as necessary)
		const allCountries = data?.map(conversion=>conversion.country)

		// Initialize an object to hold the conversion data by date and country
		let datewiseData = {};

		// Iterate through the input data
		data?.forEach(entry => {
			const { date, country, conversions } = entry;
			
			// If the date doesn't exist in datewiseData, initialize it
			if (!datewiseData[date]) {
				datewiseData[date] = { Total: 0 };
				
				// Initialize all countries with 0 conversions
				allCountries.forEach(country => {
					datewiseData[date][country] = 0;
				});
			}
			
			// Add the conversions to the specific country and to the Total
			datewiseData[date][country] += conversions;
			datewiseData[date]['Total'] += conversions;
		});

		// Convert the datewiseData object into the required array format
		let output = Object.keys(datewiseData)?.map(date => {
			return { date, ...datewiseData[date] };
		});

		const updatedOptions =produce(chartOptions,(draft)=>{
			draft.data = output
		  })
		const updatedOptions2 =produce(chartOptions2,(draft)=>{
			draft.data = output
		  })
		  setChartOptions(updatedOptions)
		  setChartOptions2(updatedOptions2)
	}
       //Fn to check and uncheck all checkboxes
	const handleCheckBoxes = (selectedRows) => {
		listOfCheckedRows = selectedRows;
		const updatedOptions =produce(chartOptions,(draft)=>{
			draft.series = [draft.series[0]]
			selectedRows.forEach(item => {
				if(item.country!=='Total')
				{
				draft.series.push({ type: 'line',  
					    xKey: 'date', 
						yKey: item.country, 
						yName: item.country, 
						stroke:item.color,
					    marker:{
						 enabled: true,
						 fill: item.color
						},
						legend:{
							item:{
								label:{
									color:item.color
								}
							}
						},
						tooltip:{
							renderer:{

							}
						}
					})
				}
			})
		  })
		setChartOptions(updatedOptions)


		
		const updatedOptions2 =produce(chartOptions2,(draft)=>{
			draft.series = [draft.series[0]]
			selectedRows.forEach(item => {
				if(item.country!=='Total')
				{
				draft.series.push({ type: 'bar',   
					    stacked: true,
					    xKey: 'date', 
						yKey: item.country, 
						yName: item.country, 
						stroke:item.color,
					    marker:{
						 enabled: true,
						 fill: item.color
						},
						legend:{
							item:{
								label:{
									color:item.color
								}
							}
						},
						tooltip:{
							renderer:{

							}
						},

					})
				}
			})
		  })
		setChartOptions2(updatedOptions2)
	};
	// To sort 
	const handleSort = (data) => {
		const mapping = {'Date Added': 'createdAt', 'Date Modified': 'updatedAt'}
		var id = data[0]?.id ? mapping[data[0].id] : ''
		setSortBy(id)
		setIsSorted(data[0]?.desc ? data[0].desc : false)
	};
    const handleColumnBtnClick = (event) => {
		handlePopUpClose();
		columnsOptionPopUpRef.current.style.display = 'block';
		var position = getEventElementPosition(event);
		columnsOptionPopUpRef.current.style.top = position.top + 'px';
		columnsOptionPopUpRef.current.style.left = position.left - 160 + 'px';
		event.stopPropagation();
	};
    	// To close the popup container
	const handlePopUpOpen = (event, popUpName, data) => {
		if(popUpName==='dateMonthPopUp')
			{
				setSelectedYear(data)
			}
		handlePopUpClose()
		setPopUp(popUpName)
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
	};
	const handlePopUpClose = () => {
		setIsPopUpVisible(false)
		columnsOptionPopUpRef.current.style.display = 'none';
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async (e, data) => {

		if(popUp === "dateMonthPopUp"){
			let selectedMonth = data
			const startDate = moment([selectedYear, selectedMonth]); // Month is zero-indexed
			const endDate = startDate.clone().endOf('month');
			const formattedStartDate = startDate.format('YYYY-MM-DD');
			const formattedEndDate = endDate.format('YYYY-MM-DD');
			setStartDate(formattedStartDate)
			setEndDate(formattedEndDate)
		}    else if(mainDateOption.includes(data)) {
        const {formattedStartDate, formattedEndDate} = calculateDates(data)
        setStartDate(formattedStartDate)
         setEndDate(formattedEndDate)
    }else if(popUp==='viewByPopUp')
		{
			setChartType(e[0].name)
		}
		handlePopUpClose();
		// getAllBssForms();
		// setDisableBulkActionBtn(true);
	};
	
    const handleChange = (field, value) => {
		if(field ==='startDate'){
			setStartDate(value)
		}else if(field==='endDate'){
			setEndDate(value)
		}
	};
	function calculateDates(selectedOption) {
		const today = moment().startOf('day');
		let startDate, endDate;
  
		switch (selectedOption) {
			case 'Today':
				startDate = today.clone().startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Yesterday':
				startDate = today.clone().subtract(1, 'day').startOf('day');
				endDate = today.clone().subtract(1, 'day').endOf('day');
				break;
			case 'This Month':
				startDate = today.clone().startOf('month');
				endDate = today.clone().endOf('month');
				break;
			case 'This Week':
				startDate = today.clone().startOf('isoWeek');
				endDate = today.clone().endOf('isoWeek');
				break;
			case 'This Year':
				startDate = today.clone().startOf('year');
				endDate = today.clone().endOf('year');
				break;
			case 'Last 7 days':
				startDate = today.clone().subtract(6, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 30 days':
				startDate = today.clone().subtract(29, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 180 days':
				startDate = today.clone().subtract(179, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 360 days':
				startDate = today.clone().subtract(359, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'This financial year':
				let date = getCurrentFinancialYear();
				startDate = date.startDate;
				endDate= date.endDate;
				break;
			case 'All time':
				startDate = moment(0); // Unix epoch
				endDate = today.clone().endOf('day');
				break;
			default:
				// Handle 'Select' or unknown option
				startDate = null;
				endDate = null;
				break;
		}
		const formattedStartDate = startDate.format('YYYY-MM-DD');
		const formattedEndDate = endDate.format('YYYY-MM-DD');
		return { formattedStartDate, formattedEndDate };
	}

	function getCurrentFinancialYear() {
		const today = moment();
		const currentMonth = today.month();
		const currentYear = today.year();
  
		let startYear, endYear;
  
		// If current month is April or later, financial year starts in the current year
		if (currentMonth >= 3) { // April is the fourth month (zero-based index)
			startYear = currentYear;
			endYear = currentYear + 1;
		} else { // Otherwise, financial year started in the previous year
			startYear = currentYear - 1;
			endYear = currentYear;
		}
  
		const startDate = moment(`${startYear}-04-01`).startOf('day');
		const endDate = moment(`${endYear}-03-31`).endOf('day');
  
		return { startDate, endDate };
	}

    return <LeadsView 
                deferredData={deferredData}
                handleCheckBoxes={handleCheckBoxes}
                handleSort={handleSort}
                
				mainDateOption={mainDateOption}
				years={years}
				months={months}
				startDate={startDate}
				endDate={endDate}
                setStartDate={setStartDate}
				setEndDate={setEndDate}
				chartOptions={chartOptions}
				chartOptions2={chartOptions2}

				// Start: Control Pop Up 
				popUp={popUp}
				popUpPosition = {popUpPosition}
				isPopUpVisible = {isPopUpVisible}
				handlePopUpClose = {handlePopUpClose} 
				handlePopUpOpen = {handlePopUpOpen} 
				handlePopUpSave= {handlePopUpSave}

				// Fullscreen
				fullscreenElementRef={fullscreenElementRef}
				toggleFullscreen={()=>toggleFullscreen(fullscreenElementRef)}
				//ColumnButton and popup
				columnsOptionPopUpRef={columnsOptionPopUpRef}
				handleColumnBtnClick={handleColumnBtnClick}

				handleChange={handleChange}
				chartType={chartType}
    
    />
}


export default Geography