import React,{useState} from "react";
import './docVerificationCard.css'

function DocVerificationCard(props){
   const [zoom, setZoom]= useState(100)
    return(
        <>
          <div className="documentVerificationCard" onClick={(event)=>{props.handleCardClick(event); event.stopPropagation();}}>     
                <div className="cardBody" >
                    {props.images.length!==0?
                    <div className="cardImages"  style={{display: zoom<150? 'flex': 'block'}}>
                     {props.images.map((img, index)=>
                        {return <img key={index} src={img} 
                                style={{
                                   width: Number(zoom*0.7)+'%',
                                   marginBottom: '10px'
                              }}/>}
                     )
                    }
                     </div>
                     :
                     <div className="pdfContainer"
                           style={{
                              transform: `scale(${zoom/200}) translate(0,0)`,
                              transformOrigin: 'top left',
                              width:`${zoom *0.45+"%"}`,
                              height:`${zoom *0.45+"%"}`,
                              padding: '20px',
                              display: zoom<100 ? 'flex': 'block',
                              justifyContent: 'center',
                              alignItems: 'center',
                              
                              }}
                              >
                         {props.children}
                    </div>
                     }


                     <div className="zoomControls">
                          <div onClick={(event)=>{ 
                              if(zoom > 100) { 
                                   setZoom(parseInt(zoom-50))}
                                    event.stopPropagation();}}>-</div>
                          <input type="text" value={zoom} onClick={(event)=>{event.stopPropagation();}}/>
                          <div  onClick={(event)=>{
                              if(zoom< 350){
                                   setZoom(parseInt(zoom+50))
                               } 
                               event.stopPropagation();}}>+</div>
                     </div>
                     <div className="pageCount">
                     {props.images.length}
                      /{props.images.length}
                     </div>
                </div>
                <div className="cardFooter" >
                    <span style={{cursor: 'pointer'}} >
                      {props.name}</span> 
                    <input type="checkbox" checked={props.isChecked}
                              onClick={(event) => {event.stopPropagation();}}
                               onChange={(event) => { props.handleCheckBoxes(event); event.stopPropagation();}}></input>
                </div>   
                {/* <div class="clickableDiv"
                     onClick={(event)=>{props.handleCardClick(event)}}
                > */}
                {/* </div> */}
                
          </div>
        </>
    )
}

export default DocVerificationCard;